var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-6 py-6", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "d-flex mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "pe-6 mt-1 position-relative ms-auto" },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-secondary text-xs font-weight-bold mb-2",
                        },
                        [_vm._v(" Team members: ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex align-center justify-center" },
                        [
                          _c(
                            "span",
                            { staticClass: "avatar-group d-flex" },
                            _vm._l(_vm.avatars, function (avatar, index) {
                              return _c(
                                "v-tooltip",
                                {
                                  key: avatar.name,
                                  attrs: { top: "", color: "#212529" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-avatar",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "border border-white",
                                                    class:
                                                      index != 0 ? "ms-n3" : "",
                                                    attrs: { size: "36" },
                                                  },
                                                  "v-avatar",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: avatar.image,
                                                    alt: "Avatar",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v(_vm._s(avatar.name))])]
                              )
                            }),
                            1
                          ),
                        ]
                      ),
                      _c("hr", { staticClass: "vertical dark mt-0" }),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "ps-6" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ms-auto border-radius-md mt-4",
                          attrs: {
                            icon: "",
                            outlined: "",
                            color: "#0A3542",
                            width: "40px",
                            height: "40px",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "12" } }, [
                            _vm._v("fa fa-plus"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("v-col", { attrs: { lg: "9" } }, [_c("calendar-widget")], 1),
              _c(
                "v-col",
                { attrs: { lg: "3" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl" },
                    [
                      _c("div", { staticClass: "px-4 pt-4" }, [
                        _c(
                          "h6",
                          { staticClass: "text-h6 font-weight-bold text-typo" },
                          [_vm._v("Next events")]
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "px-4 pt-4 pb-3" },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item-group",
                                { staticClass: "border-radius-sm" },
                                _vm._l(_vm.events, function (item, i) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: item.title,
                                      staticClass: "px-0 border-radius-sm",
                                      class:
                                        i < _vm.events.length - 1
                                          ? "mb-6"
                                          : " ",
                                      attrs: { ripple: false },
                                    },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          staticClass:
                                            "text-white border-radius-md px-2 py-2 me-4",
                                          attrs: {
                                            size: "50",
                                            color: item.color,
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              class:
                                                `text-gradient text-` +
                                                item.iconColor,
                                              attrs: { size: "18" },
                                            },
                                            [_vm._v(_vm._s(item.icon))]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        { staticClass: "py-0" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex flex-column",
                                            },
                                            [
                                              _c(
                                                "h6",
                                                {
                                                  staticClass:
                                                    "mb-1 text-dark text-sm font-weight-bold",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.title) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-sm text-body",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.date) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "card-shadow border-radius-xl bg-gradient-default mt-6",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "px-4 pt-4" },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "7" } }, [
                                _c(
                                  "h6",
                                  {
                                    staticClass:
                                      "text-white text-h6 font-weight-bold mb-0",
                                  },
                                  [_vm._v(" Productivity ")]
                                ),
                                _c("p", { staticClass: "text-sm text-white" }, [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-arrow-up text-success me-1",
                                    attrs: { "aria-hidden": "true" },
                                  }),
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v("4% more")]
                                  ),
                                  _vm._v(" in 2021 "),
                                ]),
                              ]),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-end",
                                  attrs: { cols: "5" },
                                },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        transition: "slide-y-transition",
                                        "offset-y": "",
                                        "offset-x": "",
                                        "min-width": "150",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "text-white",
                                                      attrs: {
                                                        icon: "",
                                                        ripple: false,
                                                        small: "",
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { size: "16" } },
                                                    [
                                                      _vm._v(
                                                        "fas fa-ellipsis-h"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        { staticClass: "py-4" },
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              staticClass:
                                                "list-item-hover-active mb-1",
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                { staticClass: "pa-0" },
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "text-secondary ls-0 font-weight-600 mb-0",
                                                    },
                                                    [_vm._v(" Action ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            {
                                              staticClass:
                                                "list-item-hover-active mb-1",
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                { staticClass: "pa-0" },
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "text-secondary ls-0 font-weight-600 mb-0",
                                                    },
                                                    [_vm._v(" Another action ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            {
                                              staticClass:
                                                "list-item-hover-active",
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                { staticClass: "pa-0" },
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass:
                                                        "text-secondary ls-0 font-weight-600 mb-0",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Something else here "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", [
                        _c("div", { staticClass: "chart" }, [
                          _c("canvas", {
                            staticClass: "chart-canvas",
                            attrs: { id: "chart-line-1", height: "100" },
                          }),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }