var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "card-shadow border-radius-xl mt-6",
      attrs: { id: "account" },
    },
    [
      _c("div", { staticClass: "px-6 py-6" }, [
        _c("h5", { staticClass: "text-h5 font-weight-bold text-typo" }, [
          _vm._v("Accounts"),
        ]),
        _c("p", { staticClass: "text-sm text-body" }, [
          _vm._v(" Here you can setup and manage your integration settings. "),
        ]),
      ]),
      _c("div", { staticClass: "px-6 pb-6 pt-4" }, [
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            _c(
              "v-avatar",
              {
                staticClass: "border-radius-lg me-4",
                attrs: { width: "48", height: "48" },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/small-logos/logo-slack.svg"),
                    alt: "Avatar",
                  },
                }),
              ]
            ),
            _c("div", [
              _c(
                "h5",
                { staticClass: "mb-0 text-typo text-h5 font-weight-bold" },
                [_vm._v("Slack")]
              ),
              _c(
                "a",
                {
                  staticClass: "text-sm text-body text-decoration-none",
                  attrs: { href: "javascript:;" },
                },
                [
                  _vm._v(" Show less "),
                  _c("i", {
                    staticClass: "fas fa-chevron-up text-xs ms-1",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
            ]),
            _vm.switch1 == true
              ? _c("p", { staticClass: "mb-0 text-body text-xs ms-auto" }, [
                  _vm._v(" Enabled "),
                ])
              : _vm._e(),
            _vm.switch1 == false
              ? _c("p", { staticClass: "mb-0 text-body text-xs ms-auto" }, [
                  _vm._v(" Disabled "),
                ])
              : _vm._e(),
            _c("v-switch", {
              staticClass: "mt-0 pt-0 ms-3 switch",
              attrs: {
                ripple: false,
                "hide-details": "",
                color: "#3a416ff2",
                inset: "",
              },
              model: {
                value: _vm.switch1,
                callback: function ($$v) {
                  _vm.switch1 = $$v
                },
                expression: "switch1",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "ps-12 pt-5 ms-4" }, [
          _c("p", { staticClass: "mb-0 text-sm text-body" }, [
            _vm._v(
              " You haven't added your Slack yet or you aren't authorized. Please add our Slack Bot to your account by clicking on "
            ),
            _c(
              "a",
              {
                staticClass: "text-decoration-none text-dark",
                attrs: { href: "javascript;" },
              },
              [_vm._v("here")]
            ),
            _vm._v(
              ". When you've added the bot, send your verification code that you have received. "
            ),
          ]),
          _c(
            "div",
            { staticClass: "d-flex bg-gray-100 border-radius-lg pa-2 my-6" },
            [
              _c(
                "p",
                {
                  staticClass:
                    "text-sm font-weight-bold text-body my-auto ps-sm-2 me-auto",
                },
                [_vm._v(" Verification Code ")]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "pa-2 border border-radius-md text-xs text-body bg-white w-25",
                },
                [_vm._v(" 1172913 ")]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "d-flex bg-gray-100 border-radius-lg pa-2 my-6 overflow-scroll",
            },
            [
              _c(
                "p",
                {
                  staticClass:
                    "text-sm font-weight-bold text-body my-auto ps-sm-2",
                },
                [_vm._v(" Connected account ")]
              ),
              _c(
                "h6",
                {
                  staticClass:
                    "text-sm ms-auto font-weight-bold text-typo me-3 my-auto ms-auto",
                },
                [_vm._v(" hello@seoteamflow.com ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bolder btn-danger bg-gradient-danger py-4 px-8 mt-auto",
                  attrs: { elevation: 0, color: "#10A8DD", small: "" },
                },
                [_vm._v(" Delete ")]
              ),
            ],
            1
          ),
        ]),
        _c("hr", { staticClass: "horizontal dark mb-4" }),
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            _c(
              "v-avatar",
              {
                staticClass: "border-radius-lg me-4",
                attrs: { width: "48", height: "48" },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/small-logos/logo-spotify.svg"),
                    alt: "Avatar",
                  },
                }),
              ]
            ),
            _c("div", [
              _c(
                "h5",
                { staticClass: "mb-0 text-typo text-h5 font-weight-bold" },
                [_vm._v("Spotify")]
              ),
              _c("p", { staticClass: "text-sm text-body mb-0" }, [
                _vm._v("Music"),
              ]),
            ]),
            _vm.switch2 == true
              ? _c("p", { staticClass: "mb-0 text-body text-xs ms-auto" }, [
                  _vm._v(" Enabled "),
                ])
              : _vm._e(),
            _vm.switch2 == false
              ? _c("p", { staticClass: "mb-0 text-body text-xs ms-auto" }, [
                  _vm._v(" Disabled "),
                ])
              : _vm._e(),
            _c("v-switch", {
              staticClass: "mt-0 pt-0 ms-3 switch",
              attrs: {
                ripple: false,
                "hide-details": "",
                color: "#3a416ff2",
                inset: "",
              },
              model: {
                value: _vm.switch2,
                callback: function ($$v) {
                  _vm.switch2 = $$v
                },
                expression: "switch2",
              },
            }),
          ],
          1
        ),
        _c("hr", { staticClass: "horizontal dark my-4" }),
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            _c(
              "v-avatar",
              {
                staticClass: "border-radius-lg me-4",
                attrs: { width: "48", height: "48" },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/small-logos/logo-atlassian.svg"),
                    alt: "Avatar",
                  },
                }),
              ]
            ),
            _c("div", [
              _c(
                "h5",
                { staticClass: "mb-0 text-typo text-h5 font-weight-bold" },
                [_vm._v("Atlassian")]
              ),
              _c("p", { staticClass: "text-sm text-body mb-0" }, [
                _vm._v("Payment vendor"),
              ]),
            ]),
            _vm.switch3 == true
              ? _c("p", { staticClass: "mb-0 text-body text-xs ms-auto" }, [
                  _vm._v(" Enabled "),
                ])
              : _vm._e(),
            _vm.switch3 == false
              ? _c("p", { staticClass: "mb-0 text-body text-xs ms-auto" }, [
                  _vm._v(" Disabled "),
                ])
              : _vm._e(),
            _c("v-switch", {
              staticClass: "mt-0 pt-0 ms-3 switch",
              attrs: {
                ripple: false,
                "hide-details": "",
                color: "#3a416ff2",
                inset: "",
              },
              model: {
                value: _vm.switch3,
                callback: function ($$v) {
                  _vm.switch3 = $$v
                },
                expression: "switch3",
              },
            }),
          ],
          1
        ),
        _c("hr", { staticClass: "horizontal dark my-4" }),
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            _c(
              "v-avatar",
              {
                staticClass: "border-radius-lg me-4",
                attrs: { width: "48", height: "48" },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/small-logos/logo-asana.svg"),
                    alt: "Avatar",
                  },
                }),
              ]
            ),
            _c("div", [
              _c(
                "h5",
                { staticClass: "mb-0 text-typo text-h5 font-weight-bold" },
                [_vm._v("Asana")]
              ),
              _c("p", { staticClass: "text-sm text-body mb-0" }, [
                _vm._v("Organize your team"),
              ]),
            ]),
            _vm.switch4 == true
              ? _c("p", { staticClass: "mb-0 text-body text-xs ms-auto" }, [
                  _vm._v(" Enabled "),
                ])
              : _vm._e(),
            _vm.switch4 == false
              ? _c("p", { staticClass: "mb-0 text-body text-xs ms-auto" }, [
                  _vm._v(" Disabled "),
                ])
              : _vm._e(),
            _c("v-switch", {
              staticClass: "mt-0 pt-0 ms-3 switch",
              attrs: {
                ripple: false,
                "hide-details": "",
                color: "#3a416ff2",
                inset: "",
              },
              model: {
                value: _vm.switch4,
                callback: function ($$v) {
                  _vm.switch4 = $$v
                },
                expression: "switch4",
              },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }