var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "py-6", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { md: "6" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "6", cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "border-radius-xl",
                          style: `background-image: url(${require("@/assets/img/curved-images/white-curved.jpeg")}); background-size: cover;`,
                        },
                        [
                          _c("span", {
                            staticClass:
                              "mask bg-gradient-default opacity-9 border-radius-xl",
                          }),
                          _c(
                            "div",
                            { staticClass: "px-4 py-4 position-relative" },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-start",
                                      attrs: { cols: "8" },
                                    },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          staticClass: "shadow",
                                          attrs: {
                                            color: "bg-white",
                                            rounded: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { size: "18" } },
                                            [
                                              _vm._v(
                                                " ni ni-circle-08 text-default text-gradient "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "h5",
                                        {
                                          staticClass:
                                            "text-white text-h5 font-weight-bolder mb-0 mt-5",
                                        },
                                        [_vm._v(" 1600 ")]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "text-white text-sm" },
                                        [_vm._v("Users Active")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-end",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          staticClass: "me-6",
                                          attrs: {
                                            transition: "slide-y-transition",
                                            "offset-y": "",
                                            "offset-x": "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "text-white mb-16",
                                                          attrs: {
                                                            icon: "",
                                                            ripple: false,
                                                            small: "",
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { size: "16" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fas fa-ellipsis-h"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            { staticClass: "py-0" },
                                            [
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticClass:
                                                    "list-item-hover-active",
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "ls-0 text-body font-weight-600 mb-0",
                                                        },
                                                        [_vm._v(" Action ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticClass:
                                                    "list-item-hover-active",
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "ls-0 text-body font-weight-600 mb-0",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Another action "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticClass:
                                                    "list-item-hover-active",
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "ls-0 text-body font-weight-600 mb-0",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Something else here "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-white text-sm text-end font-weight-bolder mt-auto mb-0",
                                        },
                                        [_vm._v(" +55% ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { md: "6", cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "border-radius-xl",
                          style: `background-image: url(${require("@/assets/img/curved-images/white-curved.jpeg")}); background-size: cover;`,
                        },
                        [
                          _c("span", {
                            staticClass:
                              "mask bg-gradient-default opacity-9 border-radius-xl",
                          }),
                          _c(
                            "div",
                            { staticClass: "px-4 py-4 position-relative" },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-start",
                                      attrs: { cols: "8" },
                                    },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          staticClass: "shadow",
                                          attrs: {
                                            color: "bg-white",
                                            rounded: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { size: "18" } },
                                            [
                                              _vm._v(
                                                " ni ni-active-40 text-default text-gradient "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "h5",
                                        {
                                          staticClass:
                                            "text-white text-h5 font-weight-bolder mb-0 mt-5",
                                        },
                                        [_vm._v(" 357 ")]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "text-white text-sm" },
                                        [_vm._v("Click Events")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-end",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          staticClass: "me-6",
                                          attrs: {
                                            transition: "slide-y-transition",
                                            "offset-y": "",
                                            "offset-x": "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "text-white mb-16",
                                                          attrs: {
                                                            icon: "",
                                                            ripple: false,
                                                            small: "",
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { size: "16" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fas fa-ellipsis-h"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            { staticClass: "py-0" },
                                            [
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticClass:
                                                    "list-item-hover-active",
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "ls-0 text-body font-weight-600 mb-0",
                                                        },
                                                        [_vm._v(" Action ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticClass:
                                                    "list-item-hover-active",
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "ls-0 text-body font-weight-600 mb-0",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Another action "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticClass:
                                                    "list-item-hover-active",
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "ls-0 text-body font-weight-600 mb-0",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Something else here "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-white text-sm text-end font-weight-bolder mt-auto mb-0",
                                        },
                                        [_vm._v(" +124% ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "6", cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "border-radius-xl",
                          style: `background-image: url(${require("@/assets/img/curved-images/white-curved.jpeg")}); background-size: cover;`,
                        },
                        [
                          _c("span", {
                            staticClass:
                              "mask bg-gradient-default opacity-9 border-radius-xl",
                          }),
                          _c(
                            "div",
                            { staticClass: "px-4 py-4 position-relative" },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-start",
                                      attrs: { cols: "8" },
                                    },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          staticClass: "shadow",
                                          attrs: {
                                            color: "bg-white",
                                            rounded: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { size: "18" } },
                                            [
                                              _vm._v(
                                                " ni ni-cart text-default text-gradient "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "h5",
                                        {
                                          staticClass:
                                            "text-white text-h5 font-weight-bolder mb-0 mt-5",
                                        },
                                        [_vm._v(" 2300 ")]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "text-white text-sm" },
                                        [_vm._v("Purchases")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-end",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          staticClass: "me-6",
                                          attrs: {
                                            transition: "slide-y-transition",
                                            "offset-y": "",
                                            "offset-x": "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "text-white mb-16",
                                                          attrs: {
                                                            icon: "",
                                                            ripple: false,
                                                            small: "",
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { size: "16" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fas fa-ellipsis-h"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            { staticClass: "py-0" },
                                            [
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticClass:
                                                    "list-item-hover-active",
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "ls-0 text-body font-weight-600 mb-0",
                                                        },
                                                        [_vm._v(" Action ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticClass:
                                                    "list-item-hover-active",
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "ls-0 text-body font-weight-600 mb-0",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Another action "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticClass:
                                                    "list-item-hover-active",
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "ls-0 text-body font-weight-600 mb-0",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Something else here "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-white text-sm text-end font-weight-bolder mt-auto mb-0",
                                        },
                                        [_vm._v(" +15% ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { md: "6", cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "border-radius-xl",
                          style: `background-image: url(${require("@/assets/img/curved-images/white-curved.jpeg")}); background-size: cover;`,
                        },
                        [
                          _c("span", {
                            staticClass:
                              "mask bg-gradient-default opacity-9 border-radius-xl",
                          }),
                          _c(
                            "div",
                            { staticClass: "px-4 py-4 position-relative" },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-start",
                                      attrs: { cols: "8" },
                                    },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          staticClass: "shadow",
                                          attrs: {
                                            color: "bg-white",
                                            rounded: "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { size: "18" } },
                                            [
                                              _vm._v(
                                                " ni ni-like-2 text-default text-gradient "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "h5",
                                        {
                                          staticClass:
                                            "text-white text-h5 font-weight-bolder mb-0 mt-5",
                                        },
                                        [_vm._v(" 940 ")]
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "text-white text-sm" },
                                        [_vm._v("Likes")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "text-end",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          staticClass: "me-6",
                                          attrs: {
                                            transition: "slide-y-transition",
                                            "offset-y": "",
                                            "offset-x": "",
                                            "min-width": "150",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "text-white mb-16",
                                                          attrs: {
                                                            icon: "",
                                                            ripple: false,
                                                            small: "",
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { size: "16" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "fas fa-ellipsis-h"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            { staticClass: "py-0" },
                                            [
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticClass:
                                                    "list-item-hover-active",
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "ls-0 text-body font-weight-600 mb-0",
                                                        },
                                                        [_vm._v(" Action ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticClass:
                                                    "list-item-hover-active",
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "ls-0 text-body font-weight-600 mb-0",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Another action "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item",
                                                {
                                                  staticClass:
                                                    "list-item-hover-active",
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-content",
                                                    { staticClass: "pa-0" },
                                                    [
                                                      _c(
                                                        "v-list-item-title",
                                                        {
                                                          staticClass:
                                                            "ls-0 text-body font-weight-600 mb-0",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Something else here "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-white text-sm text-end font-weight-bolder mt-auto mb-0",
                                        },
                                        [_vm._v(" +90% ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { lg: "6", cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "card-shadow border-radius-xl h-100" },
                [
                  _c("div", { staticClass: "px-4 pt-4" }, [
                    _c(
                      "h6",
                      {
                        staticClass: "mb-0 text-h6 text-typo font-weight-bold",
                      },
                      [_vm._v("Reviews")]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "px-4 pt-4" },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item-group",
                            { staticClass: "border-radius-sm" },
                            [
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "px-0 border-radius-sm mb-4",
                                  attrs: { ripple: false },
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "py-0" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex mb-2" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "me-2 text-sm font-weight-bold text-capitalize text-body",
                                            },
                                            [_vm._v("Positive reviews")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ms-auto text-sm font-weight-bold text-body",
                                            },
                                            [_vm._v("80%")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c("v-progress-linear", {
                                            attrs: {
                                              value: "80",
                                              height: "6",
                                              rounded: "",
                                              "background-color": "#e9ecef",
                                              color: "#2152ff",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "px-0 border-radius-sm mb-4",
                                  attrs: { ripple: false },
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "py-0" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex mb-2" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "me-2 text-sm font-weight-bold text-capitalize text-body",
                                            },
                                            [_vm._v("Neutral Reviews")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ms-auto text-sm font-weight-bold text-body",
                                            },
                                            [_vm._v("17%")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c("v-progress-linear", {
                                            attrs: {
                                              value: "17",
                                              height: "6",
                                              rounded: "",
                                              "background-color": "#e9ecef",
                                              color: "#141727",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "px-0 border-radius-sm mb-4",
                                  attrs: { ripple: false },
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "py-0" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex mb-2" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "me-2 text-sm font-weight-bold text-capitalize text-body",
                                            },
                                            [_vm._v("Negative reviews")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "ms-auto text-sm font-weight-bold text-body",
                                            },
                                            [_vm._v("3%")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        [
                                          _c("v-progress-linear", {
                                            attrs: {
                                              value: "3",
                                              height: "6",
                                              rounded: "",
                                              "background-color": "#e9ecef",
                                              color: "#ea0606",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "px-4 pb-4 d-flex align-center mt-8" },
                    [
                      _c("div", { staticClass: "w-60" }, [
                        _c("p", { staticClass: "text-sm text-body" }, [
                          _vm._v(" More than "),
                          _c("b", [_vm._v("1,500,000")]),
                          _vm._v(
                            " developers used SEO Team Flow's products and over "
                          ),
                          _c("b", [_vm._v("700,000")]),
                          _vm._v(" projects were created. "),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "w-40 text-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "font-weight-bolder btn-default bg-gradient-default py-5 px-4",
                              attrs: { color: "#10A8DD", small: "" },
                            },
                            [_vm._v(" View all reviews ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [_c("v-col", { attrs: { cols: "12" } }, [_c("reports-table")], 1)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }