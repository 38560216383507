var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-6 py-6", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "mx-auto mt-8", attrs: { md: "8" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl" },
                    [
                      _c("div", { staticClass: "pa-4" }, [
                        _c(
                          "h5",
                          { staticClass: "text-h5 text-typo font-weight-bold" },
                          [_vm._v("Alerts")]
                        ),
                      ]),
                      _c(
                        "v-card-text",
                        { staticClass: "px-4" },
                        _vm._l(_vm.alerts, function (item) {
                          return _c(
                            "v-alert",
                            {
                              key: item.type,
                              class: `alert alert-` + item.type,
                              attrs: {
                                ripple: false,
                                "close-icon":
                                  "fas fa-times font-size-root text-white",
                                dismissible: "",
                                dark: "",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " A simple " +
                                    _vm._s(item.type) +
                                    " alert with an "
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "text-white font-weight-bold text-decoration-none",
                                    attrs: { href: "javascript:;" },
                                  },
                                  [_vm._v("example link")]
                                ),
                                _vm._v(". Give it a click if you like. "),
                              ]),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl mt-6" },
                    [
                      _c("div", { staticClass: "pa-4" }, [
                        _c(
                          "h5",
                          { staticClass: "text-h5 text-typo font-weight-bold" },
                          [_vm._v("Notifications")]
                        ),
                        _c("p", { staticClass: "text-sm text-body mb-0" }, [
                          _vm._v(
                            " Notifications on this page use v-snackbar from Vuetify. Read more details "
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "text-dark text-decoration-none",
                              attrs: {
                                href: "https://vuetifyjs.com/en/api/v-snackbar/",
                                target: "_blank",
                              },
                            },
                            [_vm._v("here")]
                          ),
                          _vm._v(". "),
                        ]),
                      ]),
                      _c(
                        "v-card-text",
                        { staticClass: "px-4 pt-4" },
                        [
                          _c(
                            "v-row",
                            _vm._l(_vm.snackbars, function (item) {
                              return _c(
                                "v-col",
                                {
                                  key: item.color,
                                  attrs: { lg: "3", sm: "6" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      key: item.name,
                                      staticClass:
                                        "font-weight-bold text-uppercase btn-primary py-2 px-6 me-2 text-xs w-100",
                                      class: `bg-gradient-` + item.class,
                                      attrs: {
                                        elevation: "0",
                                        height: "50",
                                        ripple: false,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.SnackbarShow(item.name)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(" " + _vm._s(item.name) + " "),
                                      _c("br"),
                                      _vm._v(" notification "),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _c(
                            "v-snackbar",
                            {
                              staticClass: "snackbar-shadow",
                              attrs: { top: "", color: _vm.snackbar.color },
                              scopedSlots: _vm._u([
                                {
                                  key: "action",
                                  fn: function ({ attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._b(
                                          {
                                            staticClass:
                                              "font-weight-600 text-capitalize py-3 px-6 rounded-sm",
                                            attrs: {
                                              icon: "",
                                              elevation: "0",
                                              "max-width": "136",
                                              ripple: false,
                                              height: "43",
                                              color: "rgba(255,255,255, .85)",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.snackbar.visible = false
                                              },
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { size: "13" } },
                                            [_vm._v("fas fa-times")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.snackbar.visible,
                                callback: function ($$v) {
                                  _vm.$set(_vm.snackbar, "visible", $$v)
                                },
                                expression: "snackbar.visible",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-start alert-notify",
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "text-white mr-5",
                                      attrs: { size: "24" },
                                    },
                                    [_vm._v("ni ni-bell-55")]
                                  ),
                                  _c("p", { staticClass: "mb-0" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "font-size-root font-weight-600",
                                      },
                                      [_vm._v("Vuetify Snackbar")]
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      " Turning standard Vuetify alerts into awesome notifications "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }