var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "card-shadow border-radius-xl mt-6",
      attrs: { id: "change" },
    },
    [
      _c("div", { staticClass: "px-6 py-6" }, [
        _c("h5", { staticClass: "text-h5 font-weight-bold text-typo" }, [
          _vm._v("Change Password"),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "px-6 pb-6 pt-0" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "label",
                    {
                      staticClass: "text-xs text-typo font-weight-bolder ms-1",
                    },
                    [_vm._v("Current password")]
                  ),
                  _c("v-text-field", {
                    staticClass:
                      "font-size-input placeholder-lighter border border-radius-md mt-2",
                    attrs: {
                      "hide-details": "",
                      outlined: "",
                      color: "rgba(0,0,0,.6)",
                      light: "",
                      placeholder: "Current password",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "label",
                    {
                      staticClass: "text-xs text-typo font-weight-bolder ms-1",
                    },
                    [_vm._v("New password")]
                  ),
                  _c("v-text-field", {
                    staticClass:
                      "font-size-input placeholder-lighter border border-radius-md mt-2",
                    attrs: {
                      "hide-details": "",
                      outlined: "",
                      color: "rgba(0,0,0,.6)",
                      light: "",
                      placeholder: "New password",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "label",
                    {
                      staticClass: "text-xs text-typo font-weight-bolder ms-1",
                    },
                    [_vm._v("Confirm new password")]
                  ),
                  _c("v-text-field", {
                    staticClass:
                      "font-size-input placeholder-lighter border border-radius-md mt-2",
                    attrs: {
                      "hide-details": "",
                      outlined: "",
                      color: "rgba(0,0,0,.6)",
                      light: "",
                      placeholder: "Confirm new password",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "h5",
            { staticClass: "mt-10 mb-2 text-h5 font-weight-bold text-typo" },
            [_vm._v(" Password requirements ")]
          ),
          _c("p", { staticClass: "text-muted mb-2" }, [
            _vm._v(" Please follow this guide for a strong password: "),
          ]),
          _c(
            "div",
            { staticClass: "d-sm-flex" },
            [
              _c("ul", { staticClass: "text-muted ps-6 mb-0" }, [
                _c("li", [
                  _c("span", { staticClass: "text-sm" }, [
                    _vm._v("One special characters"),
                  ]),
                ]),
                _c("li", [
                  _c("span", { staticClass: "text-sm" }, [
                    _vm._v("Min 6 characters"),
                  ]),
                ]),
                _c("li", [
                  _c("span", { staticClass: "text-sm" }, [
                    _vm._v("One number (2 are recommended)"),
                  ]),
                ]),
                _c("li", [
                  _c("span", { staticClass: "text-sm" }, [
                    _vm._v("Change it often"),
                  ]),
                ]),
              ]),
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bolder btn-default bg-gradient-default py-4 px-8 ms-auto mt-sm-auto mt-4",
                  attrs: { color: "#10A8DD", small: "" },
                },
                [_vm._v(" Update password ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }