var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "py-6", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "card-shadow border-radius-xl overflow-scroll" },
                [
                  _c(
                    "div",
                    { staticClass: "px-4 py-4 d-flex" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center",
                          attrs: { lg: "1", md: "2", sm: "3", cols: "4" },
                        },
                        [
                          _c(
                            "v-avatar",
                            {
                              staticClass: "bg-gradient-primary",
                              attrs: { size: "58" },
                            },
                            [_c("v-icon", [_vm._v("fas fa-plus text-white")])],
                            1
                          ),
                          _c(
                            "p",
                            { staticClass: "mb-0 text-sm text-body mt-2" },
                            [_vm._v("Add story")]
                          ),
                        ],
                        1
                      ),
                      _vm._l(_vm.stories, function (avatar) {
                        return _c(
                          "v-col",
                          {
                            key: avatar.user,
                            staticClass: "text-center",
                            attrs: { lg: "1", md: "2", sm: "3", cols: "4" },
                          },
                          [
                            _c(
                              "v-avatar",
                              {
                                staticClass: "border border-primary px-1 py-1",
                                attrs: { size: "58" },
                              },
                              [
                                _c("v-img", {
                                  attrs: {
                                    src: avatar.image,
                                    width: "50",
                                    height: "50",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "p",
                              { staticClass: "mb-0 text-sm text-body mt-2" },
                              [_vm._v(_vm._s(avatar.user))]
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { lg: "8", cols: "12" } },
            [
              _c("v-card", { staticClass: "card-shadow border-radius-xl" }, [
                _c(
                  "div",
                  {
                    staticClass: "border-bottom d-flex align-center px-4 py-4",
                  },
                  [
                    _c("div", { staticClass: "d-flex align-center" }, [
                      _c(
                        "a",
                        {
                          staticClass: "text-decoration-none",
                          attrs: { href: "javascript:;" },
                        },
                        [
                          _c(
                            "v-avatar",
                            { attrs: { size: "48", rounded: "" } },
                            [
                              _c("v-img", {
                                attrs: {
                                  src: require("@/assets/img/team-4.jpg"),
                                  alt: "profile-image",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "mx-4" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "text-dark font-weight-600 text-sm text-decoration-none",
                            attrs: { href: "javascript:;" },
                          },
                          [_vm._v("John Snow")]
                        ),
                        _c("small", { staticClass: "d-block text-muted" }, [
                          _vm._v("3 days ago"),
                        ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "text-end ms-auto" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass:
                              "font-weight-bolder bg-gradient-primary py-4 px-7",
                            attrs: { outlined: "", color: "#fff", small: "" },
                          },
                          [
                            _c("v-icon", { attrs: { size: "8" } }, [
                              _vm._v("fas fa-plus pe-2"),
                            ]),
                            _vm._v(" Follow "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "px-4 py-4" },
                  [
                    _c("p", { staticClass: "mb-6 text-body" }, [
                      _vm._v(
                        " Personal profiles are the perfect way for you to grab their attention and persuade recruiters to continue reading your CV because you’re telling them from the off exactly why they should hire you. "
                      ),
                    ]),
                    _c("v-img", {
                      staticClass: "border-radius-lg shadow-lg",
                      attrs: {
                        src: "https://images.unsplash.com/photo-1552581234-26160f608093?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2100&q=80",
                      },
                    }),
                    _c(
                      "v-row",
                      { staticClass: "align-center px-2 mt-6 mb-2" },
                      [
                        _c("v-col", { attrs: { sm: "6" } }, [
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c("v-icon", { attrs: { size: "16" } }, [
                                  _vm._v(
                                    "ni ni-like-2 me-1 text-body cursor-pointer"
                                  ),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "text-sm text-body me-4" },
                                  [_vm._v("150")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c("v-icon", { attrs: { size: "16" } }, [
                                  _vm._v(
                                    "ni ni-chat-round me-1 text-body cursor-pointer"
                                  ),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "text-sm text-body me-4" },
                                  [_vm._v("36")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c("v-icon", { attrs: { size: "16" } }, [
                                  _vm._v(
                                    "ni ni-curved-next me-1 text-body cursor-pointer"
                                  ),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "text-sm text-body me-4" },
                                  [_vm._v("12")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("v-col", { attrs: { sm: "6" } }, [
                          _c("div", { staticClass: "d-flex align-center" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center ms-auto",
                              },
                              [
                                _c(
                                  "v-avatar",
                                  {
                                    staticClass: "border border-white ms-n2",
                                    attrs: { size: "24" },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/img/team-5.jpg"),
                                        alt: "Avatar",
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "v-avatar",
                                  {
                                    staticClass: "border border-white ms-n2",
                                    attrs: { size: "24" },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/img/team-2.jpg"),
                                        alt: "Avatar",
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "v-avatar",
                                  {
                                    staticClass: "border border-white ms-n2",
                                    attrs: { size: "24" },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/img/team-1.jpg"),
                                        alt: "Avatar",
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "small",
                              {
                                staticClass: "ps-2 font-weight-bold text-body",
                              },
                              [_vm._v("and 30+ more")]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "mb-1" }, [
                      _c("hr", { staticClass: "horizontal dark mt-1 mb-5" }),
                      _c("div", { staticClass: "d-flex" }, [
                        _c(
                          "div",
                          { staticClass: "flex-shrink-0" },
                          [
                            _c(
                              "v-avatar",
                              [
                                _c("v-img", {
                                  attrs: {
                                    src: require("@/assets/img/bruce-mars.jpg"),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "flex-grow-1 ms-4" }, [
                          _c(
                            "h5",
                            {
                              staticClass:
                                "text-h5 text-typo font-weight-bold mt-0 mb-2",
                            },
                            [_vm._v(" Michael Lewis ")]
                          ),
                          _c("p", { staticClass: "text-sm text-body" }, [
                            _vm._v(
                              " I always felt like I could do anything. That’s the main thing people are controlled by! Thoughts- their perception of themselves! "
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c("v-icon", { attrs: { size: "16" } }, [
                                _vm._v(
                                  "ni ni-like-2 me-1 mt-0 cursor-pointer text-body"
                                ),
                              ]),
                              _c(
                                "span",
                                { staticClass: "text-sm me-2 text-body" },
                                [_vm._v("3 likes")]
                              ),
                              _c("v-icon", { attrs: { size: "16" } }, [
                                _vm._v(
                                  "ni ni-curved-next me-1 mt-0 cursor-pointer text-body"
                                ),
                              ]),
                              _c(
                                "span",
                                { staticClass: "text-sm me-2 text-body" },
                                [_vm._v("2 shares")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "d-flex mt-5" }, [
                        _c(
                          "div",
                          { staticClass: "flex-shrink-0" },
                          [
                            _c(
                              "v-avatar",
                              [
                                _c("v-img", {
                                  attrs: {
                                    src: require("@/assets/img/team-5.jpg"),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "flex-grow-1 ms-4" }, [
                          _c(
                            "h5",
                            {
                              staticClass:
                                "text-h5 text-typo font-weight-bold mt-0 mb-2",
                            },
                            [_vm._v(" Jessica Stones ")]
                          ),
                          _c("p", { staticClass: "text-sm text-body" }, [
                            _vm._v(
                              " Society has put up so many boundaries, so many limitations on what’s right and wrong that it’s almost impossible to get a pure thought out. It’s like a little kid, a little boy. "
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c("v-icon", { attrs: { size: "16" } }, [
                                _vm._v(
                                  "ni ni-like-2 me-1 mt-0 cursor-pointer text-body"
                                ),
                              ]),
                              _c(
                                "span",
                                { staticClass: "text-sm me-2 text-body" },
                                [_vm._v("10 likes")]
                              ),
                              _c("v-icon", { attrs: { size: "16" } }, [
                                _vm._v(
                                  "ni ni-curved-next me-1 mt-0 cursor-pointer text-body"
                                ),
                              ]),
                              _c(
                                "span",
                                { staticClass: "text-sm me-2 text-body" },
                                [_vm._v("1 share")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "d-flex mt-6" }, [
                        _c(
                          "div",
                          { staticClass: "flex-shrink-0" },
                          [
                            _c(
                              "v-avatar",
                              [
                                _c("v-img", {
                                  attrs: {
                                    src: require("@/assets/img/team-4.jpg"),
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "flex-grow-1 ms-4" },
                          [
                            _c("v-text-field", {
                              staticClass: "border",
                              attrs: {
                                outlined: "",
                                placeholder: "Write your comment",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { lg: "4", cols: "12" } },
            [
              _vm._l(_vm.cards, function (card) {
                return _c(
                  "v-card",
                  {
                    key: card.title,
                    staticClass: "card-shadow border-radius-xl mb-6",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "px-4 py-4" },
                      [
                        _c(
                          "v-row",
                          { staticClass: "align-center" },
                          [
                            _c("v-col", { attrs: { cols: "9" } }, [
                              _c(
                                "h5",
                                {
                                  staticClass:
                                    "text-h5 font-weight-bold mb-1 text-gradient text-primary",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "text-decoration-none",
                                      attrs: { href: "javascript:;" },
                                    },
                                    [_vm._v(_vm._s(card.title))]
                                  ),
                                ]
                              ),
                            ]),
                            _c(
                              "v-col",
                              { staticClass: "text-end", attrs: { cols: "3" } },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: {
                                      transition: "slide-y-transition",
                                      "offset-y": "",
                                      "offset-x": "",
                                      "min-width": "150",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "text-secondary",
                                                      attrs: {
                                                        icon: "",
                                                        ripple: false,
                                                        small: "",
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { size: "16" } },
                                                    [
                                                      _vm._v(
                                                        "fas fa-ellipsis-v"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      { staticClass: "py-0" },
                                      [
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass:
                                              "list-item-hover-active",
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              { staticClass: "pa-0" },
                                              [
                                                _c(
                                                  "v-list-item-title",
                                                  {
                                                    staticClass:
                                                      "text-body-2 ls-0 text-typo font-weight-600 mb-0",
                                                  },
                                                  [_vm._v(" Edit Team ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass:
                                              "list-item-hover-active",
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              { staticClass: "pa-0" },
                                              [
                                                _c(
                                                  "v-list-item-title",
                                                  {
                                                    staticClass:
                                                      "text-body-2 ls-0 text-typo font-weight-600 mb-0",
                                                  },
                                                  [_vm._v(" Add Member ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass:
                                              "list-item-hover-active",
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              { staticClass: "pa-0" },
                                              [
                                                _c(
                                                  "v-list-item-title",
                                                  {
                                                    staticClass:
                                                      "text-body-2 ls-0 text-typo font-weight-600 mb-0",
                                                  },
                                                  [_vm._v(" See Details ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("hr", {
                                          staticClass: "horizontal dark",
                                        }),
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass:
                                              "list-item-hover-active",
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              { staticClass: "pa-0" },
                                              [
                                                _c(
                                                  "v-list-item-title",
                                                  {
                                                    staticClass:
                                                      "text-danger ls-0 font-weight-600 mb-0",
                                                  },
                                                  [_vm._v(" Remove Team ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("p", { staticClass: "text-body mt-3" }, [
                          _vm._v(_vm._s(card.description)),
                        ]),
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c("p", { staticClass: "mb-0 text-body" }, [
                              _vm._v("Industry:"),
                            ]),
                            _c(
                              "v-btn",
                              {
                                staticClass:
                                  "border-radius-md font-weight-bolder px-3 py-3 badge-font-size ms-auto text-body",
                                attrs: {
                                  elevation: "0",
                                  small: "",
                                  ripple: false,
                                  height: "21",
                                  color: "#e4e8ed",
                                },
                              },
                              [_vm._v(_vm._s(card.industry))]
                            ),
                          ],
                          1
                        ),
                        _c("hr", { staticClass: "horizontal dark my-4" }),
                        _c("div", { staticClass: "d-flex" }, [
                          _c("p", { staticClass: "mb-0 text-body" }, [
                            _vm._v("Rating:"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "rating ms-auto" },
                            [
                              _c("v-icon", { attrs: { size: "16" } }, [
                                _vm._v("fas fa-star text-body"),
                              ]),
                              _c("v-icon", { attrs: { size: "16" } }, [
                                _vm._v("fas fa-star text-body ms-1"),
                              ]),
                              _c("v-icon", { attrs: { size: "16" } }, [
                                _vm._v("fas fa-star text-body ms-1"),
                              ]),
                              _c("v-icon", { attrs: { size: "16" } }, [
                                _vm._v("fas fa-star text-body ms-1"),
                              ]),
                              card.rating == "partial"
                                ? _c("v-icon", { attrs: { size: "16" } }, [
                                    _vm._v(
                                      "fas fa-star-half-alt text-body ms-1"
                                    ),
                                  ])
                                : _vm._e(),
                              card.rating == "full"
                                ? _c("v-icon", { attrs: { size: "16" } }, [
                                    _vm._v("fas fa-star text-body ms-1"),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]),
                        _c("hr", { staticClass: "horizontal dark my-4" }),
                        _c("div", { staticClass: "d-flex" }, [
                          _c("p", { staticClass: "mb-0 text-body" }, [
                            _vm._v("Members:"),
                          ]),
                          _c(
                            "span",
                            { staticClass: "avatar-group d-flex ms-auto" },
                            _vm._l(card.avatars, function (avatar) {
                              return _c(
                                "v-tooltip",
                                {
                                  key: avatar.name,
                                  attrs: { bottom: "", color: "#212529" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-avatar",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "border border-white ms-n3",
                                                    attrs: { size: "24" },
                                                  },
                                                  "v-avatar",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: avatar.image,
                                                    alt: "Avatar",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v(_vm._s(avatar.name))])]
                              )
                            }),
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                )
              }),
              _vm._l(_vm.brands, function (card, i) {
                return _c(
                  "v-card",
                  { key: i, staticClass: "card-shadow border-radius-xl mb-6" },
                  [
                    _c("div", { staticClass: "px-4 py-4" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "v-avatar",
                            [_c("v-img", { attrs: { src: card.img } })],
                            1
                          ),
                          _c("div", { staticClass: "ms-2 my-auto" }, [
                            _c(
                              "h6",
                              {
                                staticClass:
                                  "mb-0 text-h6 text-typo font-weight-bold",
                              },
                              [_vm._v(" " + _vm._s(card.title) + " ")]
                            ),
                            _c("p", { staticClass: "text-xs mb-0 text-body" }, [
                              _vm._v(_vm._s(card.hour)),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c("p", { staticClass: "text-body mt-3" }, [
                        _vm._v(_vm._s(card.description)),
                      ]),
                      _c("div", { staticClass: "d-flex" }, [
                        _c("p", { staticClass: "mb-0 text-body" }, [
                          _c("b", [_vm._v("Meeting ID")]),
                          _vm._v(": " + _vm._s(card.id)),
                        ]),
                      ]),
                      _c("hr", { staticClass: "horizontal dark my-4" }),
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "font-weight-bolder btn-success bg-gradient-success py-4 px-8",
                              attrs: {
                                elevation: 0,
                                color: "#10A8DD",
                                small: "",
                              },
                            },
                            [_vm._v(" Join ")]
                          ),
                          _c(
                            "span",
                            { staticClass: "avatar-group d-flex ms-auto" },
                            _vm._l(card.avatars, function (avatar) {
                              return _c(
                                "v-tooltip",
                                {
                                  key: avatar.name,
                                  attrs: { bottom: "", color: "#212529" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-avatar",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "border border-white ms-n3",
                                                    attrs: { size: "24" },
                                                  },
                                                  "v-avatar",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: avatar.image,
                                                    alt: "Avatar",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v(_vm._s(avatar.name))])]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }