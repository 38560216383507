var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-6 py-6", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "d-flex mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "pe-6 mt-1 position-relative ms-auto" },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-secondary text-xs font-weight-bold mb-2",
                        },
                        [_vm._v(" Team members: ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex align-center justify-center" },
                        [
                          _c(
                            "span",
                            { staticClass: "avatar-group d-flex" },
                            _vm._l(_vm.avatars, function (avatar, index) {
                              return _c(
                                "v-tooltip",
                                {
                                  key: avatar.name,
                                  attrs: { top: "", color: "#212529" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-avatar",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "border border-white",
                                                    class:
                                                      index != 0 ? "ms-n3" : "",
                                                    attrs: { size: "36" },
                                                  },
                                                  "v-avatar",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("img", {
                                                  attrs: {
                                                    src: avatar.image,
                                                    alt: "Avatar",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v(_vm._s(avatar.name))])]
                              )
                            }),
                            1
                          ),
                        ]
                      ),
                      _c("hr", { staticClass: "vertical dark mt-0" }),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "ps-6" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "ms-auto btn-info bg-gradient-info border-radius-md mt-4",
                          attrs: {
                            icon: "",
                            color: "#fff",
                            width: "40px",
                            height: "40px",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "12" } }, [
                            _vm._v("fa fa-plus"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "kanban-board",
                    { attrs: { stages: _vm.stages, blocks: _vm.blocks } },
                    _vm._l(_vm.blocks, function (block) {
                      return _c(
                        "div",
                        {
                          key: block.id,
                          staticClass: "pa-1",
                          attrs: { slot: block.id },
                          slot: block.id,
                        },
                        [
                          block.image
                            ? _c(
                                "div",
                                [
                                  _c("v-img", {
                                    staticClass: "border-radius-sm mb-3",
                                    attrs: { src: block.image },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          block.badge
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "border-radius-sm font-weight-bolder px-2 py-2 badge-font-size ms-auto text-white text-xxs mb-2",
                                      class: `bg-gradient-` + block.badgeColor,
                                      attrs: {
                                        elevation: "0",
                                        small: "",
                                        ripple: false,
                                        height: "21",
                                      },
                                    },
                                    [_vm._v(_vm._s(block.badge))]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          block.title
                            ? _c("div", [
                                _c(
                                  "p",
                                  { staticClass: "text text-body mb-0" },
                                  [_vm._v(_vm._s(block.title))]
                                ),
                              ])
                            : _vm._e(),
                          block.files
                            ? _c("div", [
                                _c("div", { staticClass: "d-flex mt-3" }, [
                                  _c("div", [
                                    _c("i", {
                                      staticClass:
                                        "fa fa-paperclip me-1 text-sm",
                                      attrs: { "aria-hidden": "true" },
                                    }),
                                    _c("span", { staticClass: "text-sm" }, [
                                      _vm._v(_vm._s(block.files)),
                                    ]),
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "avatar-group ms-auto d-flex",
                                    },
                                    _vm._l(block.avatars, function (avatar) {
                                      return _c(
                                        "v-tooltip",
                                        {
                                          key: avatar.name,
                                          attrs: { top: "", color: "#212529" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "v-avatar",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "border border-white",
                                                            attrs: {
                                                              size: "24",
                                                            },
                                                          },
                                                          "v-avatar",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: avatar.image,
                                                            alt: "Avatar",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(avatar.name)),
                                          ]),
                                        ]
                                      )
                                    }),
                                    1
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }