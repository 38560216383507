<template>
  <div>
    <v-card class="card-shadow border-radius-xl">
      <div class="card-header-padding">
        <h5 class="font-weight-bold text-h5 text-typo mb-0">
          Projects
        </h5>
        <!--
        <p class="text-sm text-body mb-0">
          Features include sorting, searching, pagination, content-editing, and
          row selection.
        </p>
        -->
      </div>

      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :items="projectDataList"
          :search="search"
          class="table"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar flat height="80">
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                    hide-details
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    v-model="search"
                    placeholder="Search"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size=".875rem"
                        >fas fa-search</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>

              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    class="
                      font-weight-bold
                      text-capitalize
                      btn-primary
                      bg-gradient-primary
                      py-3
                      px-6
                      ms-3
                    "
                    >New Project</v-btn
                  >
                </template>
                <v-card class="card-shadow border-radius-xl">
                  <div class="card-header-padding card-border-bottom">
                    <span class="font-weight-bold text-h5 text-typo mb-0">{{
                      formTitle
                    }}</span>
                  </div>
                  <v-card-text class="card-padding">
                    <v-container class="px-0">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.name"
                            hide-details
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            filled
                            solo
                            height="43"
                            placeholder="Name"
                          ></v-text-field>
                        </v-col>
                      </v-row>
<!--                      <v-row>-->
<!--                        <v-col cols="12">-->
<!--                          <v-text-field-->
<!--                            v-model="editedItem.contact"-->
<!--                            hide-details-->
<!--                            class="-->
<!--                              input-style-->
<!--                              font-size-input-->
<!--                              text-light-input-->
<!--                              placeholder-light-->
<!--                              input-icon-->
<!--                            "-->
<!--                            dense-->
<!--                            flat-->
<!--                            filled-->
<!--                            solo-->
<!--                            height="43"-->
<!--                            placeholder="Contact"-->
<!--                          ></v-text-field>-->
<!--                        </v-col>-->
<!--                      </v-row>-->
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.email"
                            hide-details
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            filled
                            solo
                            height="43"
                            placeholder="Email"
                          ></v-text-field>
                        </v-col>
                      </v-row>
<!--                      <v-row>-->
<!--                        <v-col cols="12">-->
<!--                          <v-text-field-->
<!--                            v-model="editedItem.phone"-->
<!--                            hide-details-->
<!--                            class="-->
<!--                              input-style-->
<!--                              font-size-input-->
<!--                              text-light-input-->
<!--                              placeholder-light-->
<!--                              input-icon-->
<!--                            "-->
<!--                            dense-->
<!--                            flat-->
<!--                            filled-->
<!--                            solo-->
<!--                            height="43"-->
<!--                            placeholder="Phone"-->
<!--                          ></v-text-field>-->
<!--                        </v-col>-->
<!--                      </v-row>-->
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.status"
                            hide-details
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            filled
                            solo
                            height="43"
                            placeholder="Status"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions class="card-padding d-flex justify-end">
                    <v-btn
                      @click="close"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-secondary
                        bg-gradient-light
                        py-3
                        px-6
                      ">
                      Cancel
                    </v-btn>
                    <v-btn
                      @click="save"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-primary
                        bg-gradient-primary
                        py-3
                        px-6
                      "
                      >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card class="card-shadow card-padding border-radius-xl">
                  <v-card-title class="pt-0 text-h5 text-typo justify-center"
                    >Are you sure you want to delete this project?</v-card-title
                  >
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="closeDelete"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls
                        bg-gradient-light
                        py-3
                        px-6
                      "
                      >Cancel</v-btn
                    >

                    <v-btn
                      @click="deleteItemConfirm"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-bold
                        text-capitalize
                        btn-ls btn-primary
                        bg-gradient-primary
                        py-3
                        px-6
                      "
                      >Ok</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:item.name="{ item }">
            <div class="d-flex align-center">
              <span class="text-sm font-weight-normal text-body">
                {{ item.name }}
              </span>
            </div>
          </template>

<!--          <template v-slot:item.contact="{ item }">-->
<!--            <span class="text-sm font-weight-normal text-body">-->
<!--              {{ item.contact }}-->
<!--            </span>-->
<!--          </template>-->

          <template v-slot:item.email="{ item }">
            <span class="text-sm font-weight-normal text-body">
              {{ item.email }}
            </span>
          </template>

<!--          <template v-slot:item.phone="{ item }">-->
<!--            <span class="text-sm font-weight-normal text-body">-->
<!--              {{ item.phone }}-->
<!--            </span>-->
<!--          </template>-->

          <template v-slot:item.status="{ item }">
            <span class="text-sm font-weight-normal text-body">
              {{ item.status }}
            </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              @click="editItem(item)"
              icon
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="btn-ls me-2 my-2 rounded-sm"
              color="#67748e"
            >
              <v-icon size="12">ni-ruler-pencil</v-icon>
            </v-btn>

            <v-btn
              @click="deleteItem(item)"
              icon
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="btn-ls me-2 my-2 rounded-sm"
              color="#67748e"
            >
              <v-icon size="12">ni-fat-remove text-lg</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" lg="3" class="d-flex align-center">
            <span class="text-body me-3 text-sm">Items per page:</span>
            <v-text-field
              hide-details
              type="number"
              outlined
              min="-1"
              max="15"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="itemsPerPage"
              @input="itemsPerPage = parseInt($event, 10)"
              placeholder="Items per page"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#10A8DD"
              v-model="page"
              :length="pageCount"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "paginated-tables",
  data() {
    return {
      projectDataList: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      search: "",
      editedIndex: -1,
      editedItem: {
        name: "",
        contact: "",
        email: "",
        phone: "",
        status: "",
      },
      defaultItem: {
        name: "",
        contact: "",
        email: "",
        phone: "",
        status: "",
      },
      headers: [
        {
          text: "Name",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        // {
        //   text: "Contact",
        //   value: "contact",
        //   class: "text-secondary font-weight-bolder opacity-7",
        // },
        {
          text: "Email",
          value: "email",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        // {
        //   text: "Phone",
        //   value: "phone",
        //   class: "text-secondary font-weight-bolder opacity-7",
        // },
        {
          text: "Status",
          value: "status",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
      /*
      editedItem: {
        name: "",
        email: "",
        age: "",
        salary: "",
      },
      defaultItem: {
        name: "",
        email: "",
        age: "",
        salary: "",
      },
      headers: [
        {
          text: "Name",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "name",
          class: "text-secondary font-weight-bolder opacity-7 border-bottom",
        },
        {
          text: "Email",
          value: "email",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Age",
          value: "age",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Salary",
          value: "salary",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
      */
    };
  },
  methods: {

    apiSetProjectData () {
      // if (this._isEmpty(this.mainStateGet('projectData'))) {
      this.apiGetProjects()
        .then((data) => {
          this.projectDataList = data.results
          this.mainStateSet('projectData', data)
          this.setProjectListOptions()
          this.consoleLog('info', 'project data loaded:', data)
        }).catch ((error) => {
        this.mainStateSet('projectData', [])
        this.consoleLog('error', 'mainMixin.js fn(apiSetProjectData): error catch', error)
      })
      // } else {
      //   this.projectDataList = this.mainStateGet('projectData')
      // }
    },

    editItem(item) {
      this.editedIndex = this.projectDataList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.projectDataList.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.projectDataList.splice(this.editedIndex, 1);
      this.mainStateSet('projectData', this.projectDataList)
      this.updateProjectListOptions()
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.agencies[this.editedIndex], this.editedItem);
      } else {
        this.projectDataList.push(this.editedItem);
        this.mainStateSet('projectData', this.projectDataList)
        this.updateProjectListOptions()
      }
      this.close();
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Project" : "Edit Project";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
  mounted () {
    this.apiSetProjectData()
  },
};
</script>
