var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "py-6", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { lg: "4", sm: "8" } },
            [
              _c(
                "v-tabs",
                {
                  staticClass: "text-left",
                  attrs: { "background-color": "transparent" },
                },
                [
                  _c("v-tabs-slider"),
                  _c("v-tab", { attrs: { ripple: false, href: "#tab-1" } }, [
                    _c("span", { staticClass: "ms-1" }, [_vm._v("Messages")]),
                  ]),
                  _c("v-tab", { attrs: { ripple: false, href: "#tab-2" } }, [
                    _c("span", { staticClass: "ms-1" }, [_vm._v("Social")]),
                  ]),
                  _c("v-tab", { attrs: { ripple: false, href: "#tab-3" } }, [
                    _c("span", { staticClass: "ms-1" }, [_vm._v("items")]),
                  ]),
                  _c("v-tab", { attrs: { ripple: false, href: "#tab-4" } }, [
                    _c("span", { staticClass: "ms-1" }, [_vm._v("Backup")]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mx-auto", attrs: { md: "8", sm: "10" } },
            [
              _c(
                "form",
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl my-9" },
                    [
                      _c(
                        "div",
                        { staticClass: "px-6 py-6" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { md: "4", "align-self": "start" } },
                                [
                                  _c("v-img", {
                                    staticClass: "pa-2 mb-2",
                                    attrs: {
                                      src: require("@/assets/img/logo--seo-team-flow.png"),
                                      width: "30",
                                      contain: "",
                                    },
                                  }),
                                  _c(
                                    "h6",
                                    {
                                      staticClass:
                                        "text-typo text-h6 font-weight-bold mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " St. Independence Embankment, 050105 Bucharest, Romania "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    { staticClass: "d-block text-secondary" },
                                    [_vm._v("tel: +4 (074) 1090873")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-md-end mt-8 ms-auto",
                                  attrs: { lg: "3", md: "7" },
                                },
                                [
                                  _c(
                                    "h6",
                                    {
                                      staticClass:
                                        "d-block mt-2 mb-0 text-typo text-h6 font-weight-bold mb-2",
                                    },
                                    [_vm._v(" Billed to: John Doe ")]
                                  ),
                                  _c("p", { staticClass: "text-secondary" }, [
                                    _vm._v(" 4006 Locust View Drive"),
                                    _c("br"),
                                    _vm._v(" San Francisco CA"),
                                    _c("br"),
                                    _vm._v(" California "),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("br"),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "mt-auto py-0",
                                  attrs: { md: "4" },
                                },
                                [
                                  _c(
                                    "h6",
                                    {
                                      staticClass:
                                        "mb-0 text-start text-h6 font-weight-bold text-secondary",
                                    },
                                    [_vm._v(" Invoice no ")]
                                  ),
                                  _c(
                                    "h5",
                                    {
                                      staticClass:
                                        "text-start text-typo text-h5 font-weight-bold mb-0",
                                    },
                                    [_vm._v(" #0453119 ")]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "mt-auto ms-auto",
                                  attrs: { lg: "5", md: "7" },
                                },
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "mt-md-5 mt-4 text-md-end text-start",
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0",
                                          attrs: { md: "6" },
                                        },
                                        [
                                          _c(
                                            "h6",
                                            {
                                              staticClass:
                                                "text-secondary text-h6 font-weight-bold mb-0",
                                            },
                                            [_vm._v(" Invoice date: ")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0",
                                          attrs: { md: "6" },
                                        },
                                        [
                                          _c(
                                            "h6",
                                            {
                                              staticClass:
                                                "text-dark text-h6 font-weight-bold mb-0",
                                            },
                                            [_vm._v(" 06/03/2019 ")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0",
                                          attrs: { md: "6" },
                                        },
                                        [
                                          _c(
                                            "h6",
                                            {
                                              staticClass:
                                                "text-secondary text-h6 font-weight-bold mb-0",
                                            },
                                            [_vm._v(" Due date: ")]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "py-0",
                                          attrs: { md: "6" },
                                        },
                                        [
                                          _c(
                                            "h6",
                                            {
                                              staticClass:
                                                "text-dark text-h6 font-weight-bold mb-0",
                                            },
                                            [_vm._v(" 11/03/2019 ")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "px-6 py-6" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-simple-table", {
                                    staticClass: "table border-radius-xl",
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function () {
                                          return [
                                            _c("thead", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { staticClass: "ps-1" },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "mb-0 text-body text-capitalize text-md font-weight-bold",
                                                      },
                                                      [_vm._v(" Item ")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "th",
                                                  { staticClass: "pe-2" },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "mb-0 text-body text-capitalize text-md font-weight-bold",
                                                      },
                                                      [_vm._v(" Qty ")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "th",
                                                  { staticClass: "pe-2" },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "mb-0 text-body text-capitalize text-md font-weight-bold",
                                                      },
                                                      [_vm._v(" Rate ")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "th",
                                                  { staticClass: "pe-2" },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "mb-0 text-body text-capitalize text-md font-weight-bold",
                                                      },
                                                      [_vm._v(" Amount ")]
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.items,
                                                function (item, i) {
                                                  return _c(
                                                    "tr",
                                                    { key: item.title },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "text-secondary mb-0 py-3 ps-1",
                                                          class: {
                                                            "border-bottom":
                                                              i !=
                                                              _vm.items.length -
                                                                1,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-md mb-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.title
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "ps-4 text-secondary",
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-md mb-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(item.qty)
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "ps-4 text-secondary",
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-md mb-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.rate
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "ps-4 text-secondary",
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "text-md mb-0",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.amount
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                            _c("tfoot", [
                                              _c("tr", [
                                                _c("th", {
                                                  staticClass: "ps-1",
                                                }),
                                                _c("th", {
                                                  staticClass: "pe-2",
                                                }),
                                                _c(
                                                  "th",
                                                  { staticClass: "pe-2" },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "mb-0 text-h5 text-typo font-weight-bold",
                                                      },
                                                      [_vm._v(" Total ")]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "th",
                                                  { staticClass: "pe-2" },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "mb-0 text-h5 text-typo font-weight-bold",
                                                      },
                                                      [_vm._v(" $698 ")]
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "px-6 py-6 mt-md-8 mt-6" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-start",
                                  attrs: { lg: "5" },
                                },
                                [
                                  _c(
                                    "h5",
                                    {
                                      staticClass:
                                        "text-h5 mb-2 text-typo font-weight-bold",
                                    },
                                    [_vm._v(" Thank you! ")]
                                  ),
                                  _c(
                                    "p",
                                    { staticClass: "text-secondary text-sm" },
                                    [
                                      _vm._v(
                                        " If you encounter any issues related to the invoice you can contact us at: "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "h6",
                                    {
                                      staticClass:
                                        "text-secondary text-h6 font-weight-bold mb-0",
                                    },
                                    [
                                      _vm._v(" email: "),
                                      _c("span", { staticClass: "text-dark" }, [
                                        _vm._v("support@seoteamflow.com"),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-end d-flex",
                                  attrs: { lg: "7" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "font-weight-bolder btn-info bg-gradient-info mt-auto ms-auto py-5 px-6 ms-2",
                                      attrs: {
                                        ripple: false,
                                        elevation: "0",
                                        color: "#fff",
                                        small: "",
                                        onclick: "window.print()",
                                      },
                                    },
                                    [_vm._v(" Print ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }