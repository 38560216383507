var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "card-shadow border-radius-xl mt-6", attrs: { id: "2fa" } },
    [
      _c(
        "div",
        { staticClass: "px-6 py-6 d-flex" },
        [
          _c("h5", { staticClass: "text-h5 font-weight-bold text-typo" }, [
            _vm._v(" Two-factor authentication "),
          ]),
          _c(
            "v-btn",
            {
              staticClass:
                "border-radius-md font-weight-bolder px-3 py-3 badge-font-size ms-auto text-success",
              attrs: {
                elevation: "0",
                small: "",
                ripple: false,
                height: "21",
                color: "#cdf59b",
              },
            },
            [_vm._v("Enabled")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "px-6 py-6" }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("p", { staticClass: "my-auto text-body" }, [
              _vm._v("Security keys"),
            ]),
            _c(
              "p",
              { staticClass: "text-secondary text-sm ms-auto my-auto me-3" },
              [_vm._v(" No Security Keys ")]
            ),
            _c(
              "v-btn",
              {
                staticClass: "font-weight-bolder btn-outline-default py-4 px-8",
                attrs: { elevation: "0", color: "#fff", small: "" },
              },
              [_vm._v(" Add ")]
            ),
          ],
          1
        ),
        _c("hr", { staticClass: "horizontal dark my-4" }),
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("p", { staticClass: "my-auto text-body" }, [
              _vm._v("SMS Number"),
            ]),
            _c(
              "p",
              { staticClass: "text-secondary text-sm ms-auto my-auto me-3" },
              [_vm._v("+4012374423")]
            ),
            _c(
              "v-btn",
              {
                staticClass: "font-weight-bolder btn-outline-default py-4 px-8",
                attrs: { elevation: "0", color: "#fff", small: "" },
              },
              [_vm._v(" Edit ")]
            ),
          ],
          1
        ),
        _c("hr", { staticClass: "horizontal dark my-4" }),
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("p", { staticClass: "my-auto text-body" }, [
              _vm._v("Authenticator app"),
            ]),
            _c(
              "p",
              { staticClass: "text-secondary text-sm ms-auto my-auto me-3" },
              [_vm._v(" Not Configured ")]
            ),
            _c(
              "v-btn",
              {
                staticClass: "font-weight-bolder btn-outline-default py-4 px-8",
                attrs: { elevation: "0", color: "#fff", small: "" },
              },
              [_vm._v(" Set Up ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }