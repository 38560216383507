var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-6 py-6", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "mx-auto mt-9", attrs: { lg: "8" } },
                [
                  _c(
                    "h2",
                    {
                      staticClass:
                        "text-center text-h2 text-typo font-weight-bold",
                    },
                    [_vm._v(" Sweet Alerts ")]
                  ),
                  _c("p", { staticClass: "text-center text-body" }, [
                    _vm._v(
                      " A beautiful plugin, that replace the classic alert. Please check out the "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "text-decoration-none text-dark",
                        attrs: {
                          href: "https://github.com/avil13/vue-sweetalert2",
                          target: "_blank",
                        },
                      },
                      [_vm._v("full documentation.")]
                    ),
                  ]),
                  _c(
                    "v-row",
                    { staticClass: "mt-9" },
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "4", cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "card-shadow border-radius-xl text-center pa-5",
                            },
                            [
                              _c("p", { staticClass: "text-body" }, [
                                _vm._v("Basic example"),
                              ]),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "font-weight-bold text-uppercase btn-primary bg-gradient-primary py-2 px-6 me-2 text-xs",
                                  attrs: {
                                    elevation: "0",
                                    ripple: false,
                                    height: "43",
                                  },
                                  on: { click: _vm.showBasicAlert },
                                },
                                [_vm._v(" Try me!")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "4", cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "card-shadow border-radius-xl text-center pa-5",
                            },
                            [
                              _c("p", { staticClass: "text-body" }, [
                                _vm._v("A success message"),
                              ]),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "font-weight-bold text-uppercase btn-primary bg-gradient-primary py-2 px-6 me-2 text-xs",
                                  attrs: {
                                    elevation: "0",
                                    ripple: false,
                                    height: "43",
                                  },
                                  on: { click: _vm.showSuccessAlert },
                                },
                                [_vm._v(" Try me!")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "4", cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "card-shadow border-radius-xl text-center pa-5",
                            },
                            [
                              _c("p", { staticClass: "text-body" }, [
                                _vm._v("Custom HTML description"),
                              ]),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "font-weight-bold text-uppercase btn-primary bg-gradient-primary py-2 px-6 me-2 text-xs",
                                  attrs: {
                                    elevation: "0",
                                    ripple: false,
                                    height: "43",
                                  },
                                  on: { click: _vm.showCustomHtmlAlert },
                                },
                                [_vm._v(" Try me!")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "4", cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "card-shadow border-radius-xl text-center pa-5",
                            },
                            [
                              _c("p", { staticClass: "text-body" }, [
                                _vm._v("Github avatar request"),
                              ]),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "font-weight-bold text-uppercase btn-primary bg-gradient-primary py-2 px-6 me-2 text-xs",
                                  attrs: {
                                    elevation: "0",
                                    ripple: false,
                                    height: "43",
                                  },
                                  on: { click: _vm.showGithubAlert },
                                },
                                [_vm._v(" Try me!")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "4", cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "card-shadow border-radius-xl text-center pa-5",
                            },
                            [
                              _c("p", { staticClass: "text-body" }, [
                                _vm._v("A title with a text under"),
                              ]),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "font-weight-bold text-uppercase btn-primary bg-gradient-primary py-2 px-6 me-2 text-xs",
                                  attrs: {
                                    elevation: "0",
                                    ripple: false,
                                    height: "43",
                                  },
                                  on: { click: _vm.showTitleTextAlert },
                                },
                                [_vm._v(" Try me!")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "4", cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "card-shadow border-radius-xl text-center pa-5",
                            },
                            [
                              _c("p", { staticClass: "text-body" }, [
                                _vm._v("A message with auto close"),
                              ]),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "font-weight-bold text-uppercase btn-primary bg-gradient-primary py-2 px-6 me-2 text-xs",
                                  attrs: {
                                    elevation: "0",
                                    ripple: false,
                                    height: "43",
                                  },
                                  on: { click: _vm.showAutocloseAlert },
                                },
                                [_vm._v(" Try me!")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { md: "4", cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "card-shadow border-radius-xl text-center pa-5",
                            },
                            [
                              _c("p", { staticClass: "text-body" }, [
                                _vm._v(
                                  ' A warning message, with a function attached to the "Confirm" Button... '
                                ),
                              ]),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "font-weight-bold text-uppercase btn-primary bg-gradient-primary py-2 px-6 me-2 text-xs",
                                  attrs: {
                                    elevation: "0",
                                    ripple: false,
                                    height: "43",
                                  },
                                  on: { click: _vm.showWarningAlert },
                                },
                                [_vm._v(" Try me!")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "4", cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "card-shadow border-radius-xl text-center pa-5",
                            },
                            [
                              _c("p", { staticClass: "text-body" }, [
                                _vm._v(
                                  ' ...and by passing a parameter, you can execute something else for "Cancel" '
                                ),
                              ]),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "font-weight-bold text-uppercase btn-primary bg-gradient-primary py-2 px-6 me-2 text-xs",
                                  attrs: {
                                    elevation: "0",
                                    ripple: false,
                                    height: "43",
                                  },
                                  on: { click: _vm.showCancelAlert },
                                },
                                [_vm._v(" Try me!")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { md: "4", cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "card-shadow border-radius-xl text-center pa-5",
                            },
                            [
                              _c("p", { staticClass: "text-body" }, [
                                _vm._v(
                                  " Right-to-left support for Arabic, Persian, Hebrew, and other RTL languages "
                                ),
                              ]),
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "font-weight-bold text-uppercase btn-primary bg-gradient-primary py-2 px-6 me-2 text-xs",
                                  attrs: {
                                    elevation: "0",
                                    ripple: false,
                                    height: "43",
                                  },
                                  on: { click: _vm.showRtlAlert },
                                },
                                [_vm._v(" Try me!")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }