var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "card-shadow border-radius-xl z-index-2" },
    [
      _c("div", { staticClass: "pa-4 pb-0" }, [
        _c("h6", { staticClass: "text-h6 text-typo font-weight-bold" }, [
          _vm._v("Mixed chart"),
        ]),
      ]),
      _c("div", { staticClass: "card-padding" }, [
        _c("div", { staticClass: "chart" }, [
          _c("canvas", {
            staticClass: "chart-canvas",
            attrs: { id: "mixed-chart", height: "300" },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }