var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "justify-center d-flex mt-n16" },
    [
      _vm._l(_vm.plans, function (plan) {
        return _c(
          "v-col",
          { key: plan.title, attrs: { lg: "4" } },
          [
            _c("v-card", { staticClass: "card-shadow border-radius-xl" }, [
              _c(
                "div",
                { staticClass: "px-6 pt-6 pb-4 text-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "border-radius-xl font-weight-bolder px-3 py-3 badge-font-size ms-auto text-dark",
                      attrs: {
                        elevation: "0",
                        small: "",
                        ripple: false,
                        height: "21",
                        color: "#e9ecef",
                      },
                    },
                    [_vm._v(_vm._s(plan.title))]
                  ),
                  _c(
                    "h1",
                    { staticClass: "text-h1 text-typo font-weight-bold mt-1" },
                    [
                      _c("small", [_vm._v("$")]),
                      _vm._v(_vm._s(plan.price) + " "),
                    ]
                  ),
                  _c(
                    "v-list",
                    {
                      staticClass: "py-6 text-lg-start text-center",
                      attrs: { color: "transparent" },
                    },
                    _vm._l(plan.features, function (item) {
                      return _c(
                        "v-list-item",
                        { key: item.title, staticClass: "px-0 py-0" },
                        [
                          _c(
                            "v-list-item-avatar",
                            {
                              staticClass: "my-0 me-4 shadow",
                              class: `bg-gradient-` + item.color,
                              attrs: { size: 24, color: "#fff" },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "text-white",
                                  attrs: { size: "9" },
                                },
                                [_vm._v(_vm._s(item.icon))]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            { staticClass: "text-left" },
                            [
                              _c("v-list-item-title", {
                                staticClass: "text-body-1 ls-0 text-muted",
                                domProps: { textContent: _vm._s(item.title) },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "font-weight-bold btn-primary py-3 text-xs px-6 ls-0 w-100 border-radius-md",
                      class: `bg-gradient-` + plan.buttonColor,
                      attrs: { elevation: "0", ripple: false, height: "43" },
                    },
                    [
                      _vm._v(" " + _vm._s(plan.buttonText) + " "),
                      _c("v-icon", { attrs: { size: "12" } }, [
                        _vm._v("fas fa-arrow-right ms-2"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        )
      }),
      _c(
        "v-col",
        { staticClass: "mx-auto text-center mt-10", attrs: { cols: "8" } },
        [
          _c(
            "h6",
            { staticClass: "opacity-5 text-h6 text-typo font-weight-bold" },
            [_vm._v(" More than 50+ brands trust Soft ")]
          ),
        ]
      ),
      _c(
        "v-col",
        { staticClass: "mb-6", attrs: { cols: "12" } },
        [
          _c(
            "v-row",
            { staticClass: "mt-1" },
            _vm._l(_vm.brands, function (brand) {
              return _c(
                "v-col",
                {
                  key: brand.name,
                  staticClass: "mb-6",
                  attrs: { lg: "2", md: "4", cols: "6" },
                },
                [
                  _c("v-img", {
                    staticClass: "opacity-9",
                    attrs: {
                      alt: brand.name,
                      src: brand.image,
                      width: "200px",
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "mt-16 mx-auto text-center", attrs: { md: "6" } },
        [
          _c(
            "h2",
            { staticClass: "text-typo text-h2 font-weight-bolder mb-2" },
            [_vm._v(" Frequently Asked Questions ")]
          ),
          _c("p", { staticClass: "text-body" }, [
            _vm._v(
              " A lot of people don't appreciate the moment until it’s passed. I'm not trying my hardest, and I'm not trying to do "
            ),
          ]),
        ]
      ),
      _c(
        "v-col",
        { staticClass: "mx-auto", attrs: { md: "10" } },
        [
          _c(
            "v-expansion-panels",
            { attrs: { flat: "", color: "#ddd" } },
            _vm._l(_vm.panels, function (panel) {
              return _c(
                "v-expansion-panel",
                { key: panel.title, staticClass: "bg-transparent" },
                [
                  _c("v-expansion-panel-header", [
                    _c(
                      "h5",
                      { staticClass: "text-h5 font-weight-bold text-typo" },
                      [_vm._v(" " + _vm._s(panel.title) + " ")]
                    ),
                  ]),
                  _c("v-expansion-panel-content", [
                    _c("p", { staticClass: "text-body text-sm opacity-8" }, [
                      _vm._v(_vm._s(panel.description)),
                    ]),
                  ]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }