var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "py-6", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "card-shadow border-radius-xl mb-30" },
                    [
                      _c("div", { staticClass: "card-header-padding" }, [
                        _c(
                          "h6",
                          {
                            staticClass:
                              "font-weight-bold text-h6 text-typo mb-0",
                          },
                          [_vm._v("Timeline")]
                        ),
                      ]),
                      _c(
                        "v-card-text",
                        { staticClass: "card-padding px-0 pt-0" },
                        [
                          _c(
                            "v-timeline",
                            {
                              staticClass: "timeline-line-color",
                              attrs: { dense: "", "align-top": "" },
                            },
                            _vm._l(_vm.timeline, function (item, i) {
                              return _c(
                                "v-timeline-item",
                                {
                                  key: item.title + i,
                                  staticClass: "timeline",
                                  attrs: { small: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "icon",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-avatar",
                                              {
                                                attrs: {
                                                  size: "26",
                                                  color: "#ffffff",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    class:
                                                      `text-gradient text-` +
                                                      item.btn,
                                                    attrs: { size: "16" },
                                                  },
                                                  [_vm._v(_vm._s(item.icon))]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "v-card",
                                    { attrs: { width: "480" } },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass: "px-0 pt-0 pb-1 d-block",
                                        },
                                        [
                                          _c(
                                            "h6",
                                            {
                                              staticClass:
                                                "text-dark text-sm font-weight-bold mb-0 d-block",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.title) + " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-secondary font-weight-bold text-xs mb-0",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.date) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-card-text",
                                        { staticClass: "px-0" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-sm mt-3 mb-2 text-body",
                                            },
                                            [
                                              _vm._v(
                                                " People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of. "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "mt-3" },
                                            _vm._l(
                                              item.badges,
                                              function (item2) {
                                                return _c(
                                                  "v-btn",
                                                  {
                                                    key: item2.text,
                                                    staticClass:
                                                      "border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 badge-font-size",
                                                    class:
                                                      `bg-gradient-` + item.btn,
                                                    attrs: {
                                                      "v-if": item.badges,
                                                      elevation: "0",
                                                      small: "",
                                                      ripple: false,
                                                      height: "21",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item2.text))]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "6" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "card-shadow border-radius-xl mb-30 bg-gradient-default",
                    },
                    [
                      _c("div", { staticClass: "card-header-padding" }, [
                        _c(
                          "h6",
                          {
                            staticClass:
                              "font-weight-bold text-h6 text-white mb-0",
                          },
                          [_vm._v(" Timeline Dark ")]
                        ),
                      ]),
                      _c(
                        "v-card-text",
                        { staticClass: "card-padding px-0 pt-0" },
                        [
                          _c(
                            "v-timeline",
                            {
                              staticClass: "timeline-line-color",
                              attrs: { dense: "", "align-top": "" },
                            },
                            _vm._l(_vm.timeline, function (item, i) {
                              return _c(
                                "v-timeline-item",
                                {
                                  key: item.title + i,
                                  staticClass: "timeline",
                                  attrs: { small: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "icon",
                                        fn: function () {
                                          return [
                                            _c(
                                              "v-avatar",
                                              {
                                                attrs: {
                                                  size: "26",
                                                  color: "#0A3542",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    class:
                                                      `text-gradient text-` +
                                                      item.btn,
                                                    attrs: { size: "16" },
                                                  },
                                                  [_vm._v(_vm._s(item.icon))]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      attrs: {
                                        width: "480",
                                        color: "transparent",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card-title",
                                        {
                                          staticClass: "px-0 pt-0 pb-1 d-block",
                                        },
                                        [
                                          _c(
                                            "h6",
                                            {
                                              staticClass:
                                                "text-white text-sm font-weight-bold mb-0 d-block",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.title) + " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-secondary font-weight-bold text-xs mb-0",
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.date) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "v-card-text",
                                        { staticClass: "px-0" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-sm mt-3 mb-2 text-secondary",
                                            },
                                            [
                                              _vm._v(
                                                " People care about how you see the world, how you think, what motivates you, what you’re struggling with or afraid of. "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "mt-3" },
                                            _vm._l(
                                              item.badges,
                                              function (item2) {
                                                return _c(
                                                  "v-btn",
                                                  {
                                                    key: item2.text,
                                                    staticClass:
                                                      "border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 badge-font-size",
                                                    class:
                                                      `bg-gradient-` + item.btn,
                                                    attrs: {
                                                      "v-if": item.badges,
                                                      elevation: "0",
                                                      small: "",
                                                      ripple: false,
                                                      height: "21",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(item2.text))]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }