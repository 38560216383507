var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "card-shadow border-radius-xl mt-6",
      attrs: { id: "basic" },
    },
    [
      _c("div", { staticClass: "px-6 py-6" }, [
        _c("h5", { staticClass: "text-h5 font-weight-bold text-typo" }, [
          _vm._v("Basic Info"),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "px-6 pb-6 pt-0" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "label",
                    {
                      staticClass: "text-xs text-typo font-weight-bolder ms-1",
                    },
                    [_vm._v("First Name")]
                  ),
                  _c("v-text-field", {
                    staticClass:
                      "font-size-input placeholder-lighter border border-radius-md text-light-input mt-2",
                    attrs: {
                      "hide-details": "",
                      outlined: "",
                      color: "rgba(0,0,0,.6)",
                      light: "",
                      placeholder: "Alec",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "label",
                    {
                      staticClass: "text-xs text-typo font-weight-bolder ms-1",
                    },
                    [_vm._v("Last Name")]
                  ),
                  _c("v-text-field", {
                    staticClass:
                      "font-size-input placeholder-lighter border border-radius-md text-light-input mt-2",
                    attrs: {
                      "hide-details": "",
                      outlined: "",
                      color: "rgba(0,0,0,.6)",
                      light: "",
                      placeholder: "Thompson",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-0" },
            [
              _c(
                "v-col",
                { attrs: { sm: "4", cols: "12" } },
                [
                  _c(
                    "label",
                    {
                      staticClass: "text-xs text-typo font-weight-bolder ms-1",
                    },
                    [_vm._v("I'm")]
                  ),
                  _c("v-select", {
                    staticClass:
                      "input-style font-size-input text-light-input placeholder-light border-radius-md select-style mt-2 mb-0",
                    attrs: {
                      items: _vm.gender,
                      value: "Male",
                      color: "rgba(0,0,0,.6)",
                      outlined: "",
                      "single-line": "",
                      height: "36",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { sm: "8" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "5" } },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "text-xs text-typo font-weight-bolder ms-1",
                            },
                            [_vm._v("Birth Date")]
                          ),
                          _c("v-select", {
                            staticClass:
                              "input-style font-size-input text-light-input placeholder-light border-radius-md select-style mt-2 mb-0",
                            attrs: {
                              items: _vm.months,
                              value: "January",
                              color: "rgba(0,0,0,.6)",
                              outlined: "",
                              "single-line": "",
                              height: "36",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "4", cols: "3" } },
                        [
                          _c("v-select", {
                            staticClass:
                              "input-style font-size-input text-light-input placeholder-light border-radius-md select-style mt-8 mb-0",
                            attrs: {
                              items: _vm.days,
                              value: "1",
                              color: "rgba(0,0,0,.6)",
                              outlined: "",
                              "single-line": "",
                              height: "36",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "3", cols: "4" } },
                        [
                          _c("v-select", {
                            staticClass:
                              "input-style font-size-input text-light-input placeholder-light border-radius-md select-style mt-8 mb-0",
                            attrs: {
                              items: _vm.years,
                              value: "2021",
                              color: "rgba(0,0,0,.6)",
                              outlined: "",
                              "single-line": "",
                              height: "36",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "label",
                    {
                      staticClass: "text-xs text-typo font-weight-bolder ms-1",
                    },
                    [_vm._v("Email")]
                  ),
                  _c("v-text-field", {
                    staticClass:
                      "font-size-input placeholder-lighter text-light-input border border-radius-md mt-2",
                    attrs: {
                      "hide-details": "",
                      outlined: "",
                      color: "rgba(0,0,0,.6)",
                      light: "",
                      placeholder: "example@email.com",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "label",
                    {
                      staticClass: "text-xs text-typo font-weight-bolder ms-1",
                    },
                    [_vm._v("Confirmation Email")]
                  ),
                  _c("v-text-field", {
                    staticClass:
                      "font-size-input placeholder-lighter text-light-input border border-radius-md mt-2",
                    attrs: {
                      "hide-details": "",
                      outlined: "",
                      color: "rgba(0,0,0,.6)",
                      light: "",
                      placeholder: "example@email.com",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "label",
                    {
                      staticClass: "text-xs text-typo font-weight-bolder ms-1",
                    },
                    [_vm._v("Your location")]
                  ),
                  _c("v-text-field", {
                    staticClass:
                      "font-size-input placeholder-lighter text-light-input border border-radius-md mt-2",
                    attrs: {
                      "hide-details": "",
                      outlined: "",
                      color: "rgba(0,0,0,.6)",
                      light: "",
                      placeholder: "Sydney, A",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "label",
                    {
                      staticClass: "text-xs text-typo font-weight-bolder ms-1",
                    },
                    [_vm._v("Phone Number")]
                  ),
                  _c("v-text-field", {
                    staticClass:
                      "font-size-input placeholder-lighter text-light-input border border-radius-md mt-2",
                    attrs: {
                      "hide-details": "",
                      outlined: "",
                      color: "rgba(0,0,0,.6)",
                      light: "",
                      placeholder: "+40 735 631 620",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "label",
                    {
                      staticClass: "text-xs text-typo font-weight-bolder ms-1",
                    },
                    [_vm._v("Language")]
                  ),
                  _c("v-select", {
                    staticClass:
                      "input-style font-size-input text-light-input placeholder-light border-radius-md select-style mt-2 mb-0",
                    attrs: {
                      items: _vm.languages,
                      value: "English",
                      color: "rgba(0,0,0,.6)",
                      outlined: "",
                      "single-line": "",
                      height: "36",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "label",
                    {
                      staticClass: "text-xs text-typo font-weight-bolder ms-1",
                    },
                    [_vm._v("Skills")]
                  ),
                  _c("v-select", {
                    staticClass:
                      "input-style font-size-input text-light-input placeholder-light border-radius-md select-style mt-2",
                    attrs: {
                      items: _vm.skills,
                      outlined: "",
                      chips: "",
                      multiple: "",
                      "single-line": "",
                      height: "36",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "selection",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "v-chip",
                              {
                                staticClass: "py-1 px-2 my-0",
                                attrs: { label: "", color: "bg-default" },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "text-white text-caption ls-0",
                                  },
                                  [_vm._v(_vm._s(item))]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }