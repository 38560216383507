var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "card-shadow border-radius-xl" },
        [
          _c("div", { staticClass: "card-header-padding" }, [
            _c(
              "h5",
              { staticClass: "font-weight-bold text-h5 text-typo mb-0" },
              [_vm._v(" Projects ")]
            ),
          ]),
          _c(
            "v-card-text",
            { staticClass: "px-0 py-0" },
            [
              _c("v-data-table", {
                staticClass: "table",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.projectDataList,
                  search: _vm.search,
                  page: _vm.page,
                  "hide-default-footer": "",
                  "items-per-page": _vm.itemsPerPage,
                  "mobile-breakpoint": "0",
                },
                on: {
                  "update:page": function ($event) {
                    _vm.page = $event
                  },
                  "page-count": function ($event) {
                    _vm.pageCount = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c(
                          "v-toolbar",
                          { attrs: { flat: "", height: "80" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "5" } },
                                  [
                                    _c(
                                      "v-text-field",
                                      {
                                        staticClass:
                                          "input-style font-size-input text-light-input placeholder-light input-icon",
                                        attrs: {
                                          "hide-details": "",
                                          dense: "",
                                          flat: "",
                                          filled: "",
                                          solo: "",
                                          height: "43",
                                          placeholder: "Search",
                                        },
                                        model: {
                                          value: _vm.search,
                                          callback: function ($$v) {
                                            _vm.search = $$v
                                          },
                                          expression: "search",
                                        },
                                      },
                                      [
                                        _c(
                                          "template",
                                          { slot: "prepend-inner" },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "#adb5bd",
                                                  size: ".875rem",
                                                },
                                              },
                                              [_vm._v("fas fa-search")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("v-spacer"),
                            _c(
                              "v-dialog",
                              {
                                attrs: { "max-width": "500px" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "font-weight-bold text-capitalize btn-primary bg-gradient-primary py-3 px-6 ms-3",
                                                attrs: {
                                                  elevation: "0",
                                                  ripple: false,
                                                  height: "43",
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [_vm._v("New Project")]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.dialog,
                                  callback: function ($$v) {
                                    _vm.dialog = $$v
                                  },
                                  expression: "dialog",
                                },
                              },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "card-shadow border-radius-xl",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "card-header-padding card-border-bottom",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "font-weight-bold text-h5 text-typo mb-0",
                                          },
                                          [_vm._v(_vm._s(_vm.formTitle))]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-card-text",
                                      { staticClass: "card-padding" },
                                      [
                                        _c(
                                          "v-container",
                                          { staticClass: "px-0" },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass:
                                                        "input-style font-size-input text-light-input placeholder-light input-icon",
                                                      attrs: {
                                                        "hide-details": "",
                                                        dense: "",
                                                        flat: "",
                                                        filled: "",
                                                        solo: "",
                                                        height: "43",
                                                        placeholder: "Name",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedItem.name,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedItem,
                                                            "name",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editedItem.name",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass:
                                                        "input-style font-size-input text-light-input placeholder-light input-icon",
                                                      attrs: {
                                                        "hide-details": "",
                                                        dense: "",
                                                        flat: "",
                                                        filled: "",
                                                        solo: "",
                                                        height: "43",
                                                        placeholder: "Email",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedItem.email,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedItem,
                                                            "email",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editedItem.email",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass:
                                                        "input-style font-size-input text-light-input placeholder-light input-icon",
                                                      attrs: {
                                                        "hide-details": "",
                                                        dense: "",
                                                        flat: "",
                                                        filled: "",
                                                        solo: "",
                                                        height: "43",
                                                        placeholder: "Status",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedItem.status,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedItem,
                                                            "status",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editedItem.status",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-card-actions",
                                      {
                                        staticClass:
                                          "card-padding d-flex justify-end",
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6",
                                            attrs: {
                                              elevation: "0",
                                              ripple: false,
                                              height: "43",
                                            },
                                            on: { click: _vm.close },
                                          },
                                          [_vm._v(" Cancel ")]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6",
                                            attrs: {
                                              elevation: "0",
                                              ripple: false,
                                              height: "43",
                                            },
                                            on: { click: _vm.save },
                                          },
                                          [_vm._v(" Save ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-dialog",
                              {
                                attrs: { "max-width": "500px" },
                                model: {
                                  value: _vm.dialogDelete,
                                  callback: function ($$v) {
                                    _vm.dialogDelete = $$v
                                  },
                                  expression: "dialogDelete",
                                },
                              },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass:
                                      "card-shadow card-padding border-radius-xl",
                                  },
                                  [
                                    _c(
                                      "v-card-title",
                                      {
                                        staticClass:
                                          "pt-0 text-h5 text-typo justify-center",
                                      },
                                      [
                                        _vm._v(
                                          "Are you sure you want to delete this project?"
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-card-actions",
                                      { staticClass: "pb-0" },
                                      [
                                        _c("v-spacer"),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6",
                                            attrs: {
                                              elevation: "0",
                                              ripple: false,
                                              height: "43",
                                            },
                                            on: { click: _vm.closeDelete },
                                          },
                                          [_vm._v("Cancel")]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6",
                                            attrs: {
                                              elevation: "0",
                                              ripple: false,
                                              height: "43",
                                            },
                                            on: {
                                              click: _vm.deleteItemConfirm,
                                            },
                                          },
                                          [_vm._v("Ok")]
                                        ),
                                        _c("v-spacer"),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "item.name",
                    fn: function ({ item }) {
                      return [
                        _c("div", { staticClass: "d-flex align-center" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-sm font-weight-normal text-body",
                            },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "item.email",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "text-sm font-weight-normal text-body",
                          },
                          [_vm._v(" " + _vm._s(item.email) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.status",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "text-sm font-weight-normal text-body",
                          },
                          [_vm._v(" " + _vm._s(item.status) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-ls me-2 my-2 rounded-sm",
                            attrs: {
                              icon: "",
                              elevation: "0",
                              ripple: false,
                              height: "28",
                              "min-width": "36",
                              width: "36",
                              color: "#67748e",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.editItem(item)
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { size: "12" } }, [
                              _vm._v("ni-ruler-pencil"),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "btn-ls me-2 my-2 rounded-sm",
                            attrs: {
                              icon: "",
                              elevation: "0",
                              ripple: false,
                              height: "28",
                              "min-width": "36",
                              width: "36",
                              color: "#67748e",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteItem(item)
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { size: "12" } }, [
                              _vm._v("ni-fat-remove text-lg"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "card-padding" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex align-center",
                      attrs: { cols: "6", lg: "3" },
                    },
                    [
                      _c("span", { staticClass: "text-body me-3 text-sm" }, [
                        _vm._v("Items per page:"),
                      ]),
                      _c("v-text-field", {
                        staticClass:
                          "font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon",
                        attrs: {
                          "hide-details": "",
                          type: "number",
                          outlined: "",
                          min: "-1",
                          max: "15",
                          "background-color": "rgba(255,255,255,.9)",
                          color: "rgba(0,0,0,.6)",
                          light: "",
                          value: _vm.itemsPerPage,
                          placeholder: "Items per page",
                        },
                        on: {
                          input: function ($event) {
                            _vm.itemsPerPage = parseInt($event, 10)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "ml-auto d-flex justify-end",
                      attrs: { cols: "6" },
                    },
                    [
                      _c("v-pagination", {
                        staticClass: "pagination",
                        attrs: {
                          "prev-icon": "fa fa-angle-left",
                          "next-icon": "fa fa-angle-right",
                          color: "#10A8DD",
                          length: _vm.pageCount,
                          circle: "",
                        },
                        model: {
                          value: _vm.page,
                          callback: function ($$v) {
                            _vm.page = $$v
                          },
                          expression: "page",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }