var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-card", { staticClass: "mt-sm-8 mt-md-5 mt-7 bg-transparent" }, [
    _c("div", { staticClass: "card-padding pb-0" }, [
      _c(
        "h3",
        {
          staticClass:
            "text-h3 font-weight-bolder text-primary text-gradient mb-2",
        },
        [_vm._v(" Join us today ")]
      ),
      _c("p", { staticClass: "mb-0 text-body" }, [
        _vm._v(
          "Enter a user name, email and password to register with SEO Team Flow"
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "card-padding pb-4" },
      [
        _c(
          "label",
          { staticClass: "text-xs text-typo font-weight-bolder ms-1" },
          [_vm._v("User Name")]
        ),
        _c("v-text-field", {
          staticClass:
            "input-style font-size-input border border-radius-md placeholder-lighter text-color-light mb-4 mt-2",
          attrs: {
            "hide-details": "",
            outlined: "",
            "background-color": "rgba(255,255,255,.9)",
            color: "rgba(0,0,0,.6)",
            light: "",
            height: "40",
            placeholder: "Name",
          },
          model: {
            value: _vm.username,
            callback: function ($$v) {
              _vm.username = $$v
            },
            expression: "username",
          },
        }),
        _c(
          "label",
          { staticClass: "text-xs text-typo font-weight-bolder ms-1" },
          [_vm._v("Email")]
        ),
        _c("v-text-field", {
          staticClass:
            "input-style font-size-input border border-radius-md placeholder-lighter text-color-light mb-4 mt-2",
          attrs: {
            "hide-details": "",
            outlined: "",
            "background-color": "rgba(255,255,255,.9)",
            color: "rgba(0,0,0,.6)",
            light: "",
            height: "40",
            placeholder: "Email",
          },
          model: {
            value: _vm.email,
            callback: function ($$v) {
              _vm.email = $$v
            },
            expression: "email",
          },
        }),
        _c(
          "label",
          { staticClass: "text-xs text-typo font-weight-bolder ms-1" },
          [_vm._v("Password")]
        ),
        _c("v-text-field", {
          staticClass:
            "input-style font-size-input border border-radius-md placeholder-lighter text-color-light mb-4 mt-2",
          attrs: {
            "hide-details": "",
            outlined: "",
            "background-color": "rgba(255,255,255,.9)",
            color: "rgba(0,0,0,.6)",
            light: "",
            height: "40",
            placeholder: "Password",
          },
          model: {
            value: _vm.password,
            callback: function ($$v) {
              _vm.password = $$v
            },
            expression: "password",
          },
        }),
        _c("v-checkbox", {
          staticClass: "ma-0 checkbox-custom checkbox-thinner",
          attrs: { color: "#141727", ripple: false, "hide-details": "" },
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("span", { staticClass: "text-typo text-body-2 ls-0" }, [
                    _vm._v("I agree the "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "text-dark font-weight-bolder text-decoration-none",
                        attrs: { href: "javascript:;" },
                      },
                      [_vm._v("Terms and Conditions")]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.checkbox,
            callback: function ($$v) {
              _vm.checkbox = $$v
            },
            expression: "checkbox",
          },
        }),
        _c(
          "v-btn",
          {
            staticClass:
              "font-weight-bold text-uppercase btn-primary bg-gradient-primary py-2 px-6 me-2 mt-7 mb-2 w-100",
            attrs: {
              elevation: "0",
              ripple: false,
              height: "43",
              color: "#5e72e4",
              small: "",
            },
            on: {
              click: function ($event) {
                return _vm.register()
              },
            },
          },
          [_vm._v("Sign Up")]
        ),
        _c("div", { staticClass: "text-center" }, [
          _c("p", { staticClass: "text-sm text-body mt-3 mb-0" }, [
            _vm._v(" Already have an account? "),
            _c(
              "a",
              {
                staticClass:
                  "text-primary text-gradient text-decoration-none font-weight-bold",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.clickSignup.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Sign in")]
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }