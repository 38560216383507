var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mx-auto my-8", attrs: { lg: "8", cols: "12" } },
            [
              _c(
                "v-stepper",
                {
                  staticClass: "bg-transparent overflow-visible",
                  attrs: { "alt-labels": "", elevation: "0" },
                  model: {
                    value: _vm.e1,
                    callback: function ($$v) {
                      _vm.e1 = $$v
                    },
                    expression: "e1",
                  },
                },
                [
                  _c(
                    "v-stepper-header",
                    { staticClass: "shadow-0" },
                    [
                      _c(
                        "v-stepper-step",
                        { attrs: { step: "1", color: "#0A3542" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-secondary font-weight-normal",
                            },
                            [_vm._v("User Info")]
                          ),
                        ]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-stepper-step",
                        { attrs: { step: "2", color: "#0A3542" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-secondary font-weight-normal",
                            },
                            [_vm._v("Address")]
                          ),
                        ]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-stepper-step",
                        { attrs: { step: "3", color: "#0A3542" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-secondary font-weight-normal",
                            },
                            [_vm._v("Socials")]
                          ),
                        ]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-stepper-step",
                        { attrs: { step: "4", color: "#0A3542" } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "text-secondary font-weight-normal",
                            },
                            [_vm._v("Profile")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-items",
                    {
                      staticClass:
                        "border-radius-xl overflow-hidden shadow-lg mt-8",
                    },
                    [
                      _c(
                        "v-stepper-content",
                        {
                          staticClass: "bg-white border-radius-xl px-4 pt-4",
                          attrs: { step: "1" },
                        },
                        [
                          _c("v-card", [
                            _c(
                              "div",
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "font-weight-bolder text-h5 text-typo mb-0",
                                  },
                                  [_vm._v(" About me ")]
                                ),
                                _c(
                                  "p",
                                  { staticClass: "mb-0 text-sm text-body" },
                                  [_vm._v("Mandatory informations")]
                                ),
                                _c(
                                  "v-row",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { sm: "6", cols: "12" } },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-xs text-typo font-weight-bolder ms-1",
                                          },
                                          [_vm._v("First Name")]
                                        ),
                                        _c("v-text-field", {
                                          staticClass:
                                            "font-size-input placeholder-lighter text-light-input border border-radius-md mt-2",
                                          attrs: {
                                            "hide-details": "",
                                            outlined: "",
                                            color: "rgba(0,0,0,.6)",
                                            light: "",
                                            placeholder: "eg. Michael",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { sm: "6", cols: "12" } },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-xs text-typo font-weight-bolder ms-1",
                                          },
                                          [_vm._v("Last Name")]
                                        ),
                                        _c("v-text-field", {
                                          staticClass:
                                            "font-size-input placeholder-lighter text-light-input border border-radius-md mt-2",
                                          attrs: {
                                            "hide-details": "",
                                            outlined: "",
                                            color: "rgba(0,0,0,.6)",
                                            light: "",
                                            placeholder: "eg. Prior",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { sm: "6", cols: "12" } },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-xs text-typo font-weight-bolder ms-1",
                                          },
                                          [_vm._v("Company")]
                                        ),
                                        _c("v-text-field", {
                                          staticClass:
                                            "font-size-input placeholder-lighter text-light-input border border-radius-md mt-2",
                                          attrs: {
                                            "hide-details": "",
                                            outlined: "",
                                            color: "rgba(0,0,0,.6)",
                                            light: "",
                                            placeholder: "eg. SEO Team Flow",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { sm: "6", cols: "12" } },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-xs text-typo font-weight-bolder ms-1",
                                          },
                                          [_vm._v("Email Address")]
                                        ),
                                        _c("v-text-field", {
                                          staticClass:
                                            "font-size-input placeholder-lighter text-light-input border border-radius-md mt-2",
                                          attrs: {
                                            "hide-details": "",
                                            outlined: "",
                                            color: "rgba(0,0,0,.6)",
                                            light: "",
                                            placeholder:
                                              "eg. soft@dashboard.com",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { sm: "6", cols: "12" } },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-xs text-typo font-weight-bolder ms-1",
                                          },
                                          [_vm._v("Password")]
                                        ),
                                        _c("v-text-field", {
                                          staticClass:
                                            "font-size-input placeholder-lighter text-light-input border border-radius-md mt-2",
                                          attrs: {
                                            "hide-details": "",
                                            outlined: "",
                                            color: "rgba(0,0,0,.6)",
                                            light: "",
                                            placeholder: "******",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { sm: "6", cols: "12" } },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-xs text-typo font-weight-bolder ms-1",
                                          },
                                          [_vm._v("Repeat Password")]
                                        ),
                                        _c("v-text-field", {
                                          staticClass:
                                            "font-size-input placeholder-lighter text-light-input border border-radius-md mt-2",
                                          attrs: {
                                            "hide-details": "",
                                            outlined: "",
                                            color: "rgba(0,0,0,.6)",
                                            light: "",
                                            placeholder: "******",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "text-end" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "font-weight-bold text-xs btn-default bg-gradient-default py-5 px-6 mt-6 mb-2 me-2",
                                    attrs: {
                                      ripple: false,
                                      elevation: 0,
                                      color: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.e1 = 2
                                      },
                                    },
                                  },
                                  [_vm._v(" Next ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-content",
                        {
                          staticClass: "bg-white border-radius-xl px-4 pt-4",
                          attrs: { step: "2" },
                        },
                        [
                          _c("v-card", [
                            _c(
                              "div",
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "font-weight-bolder text-h5 text-typo mb-7",
                                  },
                                  [_vm._v(" Address ")]
                                ),
                                _c(
                                  "v-row",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-xs text-typo font-weight-bolder ms-1",
                                          },
                                          [_vm._v("Address 1")]
                                        ),
                                        _c("v-text-field", {
                                          staticClass:
                                            "font-size-input placeholder-lighter text-light-input border border-radius-md mt-2",
                                          attrs: {
                                            "hide-details": "",
                                            outlined: "",
                                            color: "rgba(0,0,0,.6)",
                                            light: "",
                                            placeholder: "eg. Street 111",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-xs text-typo font-weight-bolder ms-1",
                                          },
                                          [_vm._v("Address 2")]
                                        ),
                                        _c("v-text-field", {
                                          staticClass:
                                            "font-size-input placeholder-lighter text-light-input border border-radius-md mt-2",
                                          attrs: {
                                            "hide-details": "",
                                            outlined: "",
                                            color: "rgba(0,0,0,.6)",
                                            light: "",
                                            placeholder: "eg. Street 221",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { sm: "6", cols: "12" } },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-xs text-typo font-weight-bolder ms-1",
                                          },
                                          [_vm._v("City")]
                                        ),
                                        _c("v-text-field", {
                                          staticClass:
                                            "font-size-input placeholder-lighter text-light-input border border-radius-md mt-2",
                                          attrs: {
                                            "hide-details": "",
                                            outlined: "",
                                            color: "rgba(0,0,0,.6)",
                                            light: "",
                                            placeholder: "eg. Tokyo",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { sm: "3", cols: "12" } },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-xs text-typo font-weight-bolder ms-1",
                                          },
                                          [_vm._v("State")]
                                        ),
                                        _c("v-text-field", {
                                          staticClass:
                                            "font-size-input placeholder-lighter text-light-input border border-radius-md mt-2",
                                          attrs: {
                                            "hide-details": "",
                                            outlined: "",
                                            color: "rgba(0,0,0,.6)",
                                            light: "",
                                            placeholder: "...",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      { attrs: { sm: "3", cols: "12" } },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-xs text-typo font-weight-bolder ms-1",
                                          },
                                          [_vm._v("Zip")]
                                        ),
                                        _c("v-text-field", {
                                          staticClass:
                                            "font-size-input placeholder-lighter text-light-input border border-radius-md mt-2",
                                          attrs: {
                                            "hide-details": "",
                                            outlined: "",
                                            color: "rgba(0,0,0,.6)",
                                            light: "",
                                            placeholder: "7 letters",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "font-weight-bold text-xs text-dark btn-light bg-gradient-light py-5 px-6 mt-6 mb-2 ms-2",
                                    attrs: { ripple: false, elevation: 0 },
                                    on: {
                                      click: function ($event) {
                                        _vm.e1 = 1
                                      },
                                    },
                                  },
                                  [_vm._v(" Prev ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "font-weight-bold text-xs btn-default bg-gradient-default py-5 px-6 mt-6 mb-2 me-2 ms-auto",
                                    attrs: {
                                      ripple: false,
                                      elevation: 0,
                                      color: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.e1 = 3
                                      },
                                    },
                                  },
                                  [_vm._v(" Next ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-content",
                        {
                          staticClass: "bg-white border-radius-xl px-4 pt-4",
                          attrs: { step: "3" },
                        },
                        [
                          _c("v-card", { attrs: { height: "390px" } }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "font-weight-bolder text-h5 text-typo mb-7",
                                  },
                                  [_vm._v(" Socials ")]
                                ),
                                _c(
                                  "v-row",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-xs text-typo font-weight-bolder ms-1",
                                          },
                                          [_vm._v("Twitter Handle")]
                                        ),
                                        _c("v-text-field", {
                                          staticClass:
                                            "font-size-input placeholder-lighter text-light-input border border-radius-md mt-2",
                                          attrs: {
                                            "hide-details": "",
                                            outlined: "",
                                            color: "rgba(0,0,0,.6)",
                                            light: "",
                                            placeholder: "@soft",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-xs text-typo font-weight-bolder ms-1",
                                          },
                                          [_vm._v("Facebook Account")]
                                        ),
                                        _c("v-text-field", {
                                          staticClass:
                                            "font-size-input placeholder-lighter text-light-input border border-radius-md mt-2",
                                          attrs: {
                                            "hide-details": "",
                                            outlined: "",
                                            color: "rgba(0,0,0,.6)",
                                            light: "",
                                            placeholder: "https://...",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-xs text-typo font-weight-bolder ms-1",
                                          },
                                          [_vm._v("Instagram Account")]
                                        ),
                                        _c("v-text-field", {
                                          staticClass:
                                            "font-size-input placeholder-lighter text-light-input border border-radius-md mt-2",
                                          attrs: {
                                            "hide-details": "",
                                            outlined: "",
                                            color: "rgba(0,0,0,.6)",
                                            light: "",
                                            placeholder: "https://...",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "font-weight-bold text-xs text-dark btn-light bg-gradient-light py-5 px-6 mt-6 mb-0",
                                    attrs: { ripple: false, elevation: 0 },
                                    on: {
                                      click: function ($event) {
                                        _vm.e1 = 2
                                      },
                                    },
                                  },
                                  [_vm._v(" Prev ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "font-weight-bold text-xs btn-default bg-gradient-default py-5 px-6 mt-6 mb-0 ms-auto",
                                    attrs: {
                                      ripple: false,
                                      elevation: 0,
                                      color: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.e1 = 4
                                      },
                                    },
                                  },
                                  [_vm._v(" Next ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-content",
                        {
                          staticClass: "bg-white border-radius-xl px-4 pt-4",
                          attrs: { step: "4" },
                        },
                        [
                          _c("v-card", [
                            _c(
                              "div",
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass:
                                      "font-weight-bolder text-h5 text-typo mb-7",
                                  },
                                  [_vm._v(" Profile ")]
                                ),
                                _c(
                                  "v-row",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-xs text-typo font-weight-bolder ms-1",
                                          },
                                          [_vm._v("Public Email")]
                                        ),
                                        _c("v-text-field", {
                                          staticClass:
                                            "font-size-input placeholder-lighter text-light-input border border-radius-md mt-2",
                                          attrs: {
                                            "hide-details": "",
                                            outlined: "",
                                            color: "rgba(0,0,0,.6)",
                                            light: "",
                                            placeholder:
                                              "Use an address you don't use frequently",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-row",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "text-xs text-typo font-weight-bolder ms-1",
                                          },
                                          [_vm._v("Bio")]
                                        ),
                                        _c("v-textarea", {
                                          staticClass:
                                            "font-size-input border text-light-input border-radius-md mt-2",
                                          attrs: {
                                            outlined: "",
                                            color: "rgba(0,0,0,.6)",
                                            value:
                                              "Say a few words about who you are or what you're working on.",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "font-weight-bold placeholder-lighter text-xs text-dark btn-light bg-gradient-light py-5 px-6 mt-6 mb-2 ms-2",
                                    attrs: { ripple: false, elevation: 0 },
                                    on: {
                                      click: function ($event) {
                                        _vm.e1 = 3
                                      },
                                    },
                                  },
                                  [_vm._v(" Prev ")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "font-weight-bold text-xs bg-gradient-default py-5 px-6 mt-6 mb-2 me-2 ms-auto",
                                    attrs: {
                                      ripple: false,
                                      elevation: 0,
                                      color: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.e1 = 4
                                      },
                                    },
                                  },
                                  [_vm._v(" Send ")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }