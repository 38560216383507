<template>
  <v-container fluid class="py-3">
    <v-row>
      <v-stepper
        alt-labels
        v-model="stepperModel1"
        class="bg-transparent w-100"
        elevation="0"
      >
        <v-row>
          <v-col class="col-12 mx-auto mt-1">
            <v-stepper-header class="shadow-0">
              <v-stepper-step step="1" color="#0A3542">
                <span class="text-secondary font-weight-normal">Project</span>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="2" color="#0A3542">
                <span class="text-secondary font-weight-normal">Purpose</span>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3" color="#0A3542">
                <span class="text-secondary font-weight-normal">Brands</span>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="4" color="#0A3542">
                <span class="text-secondary font-weight-normal">Competitors</span>
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="5" color="#0A3542">
                <span class="text-secondary font-weight-normal">Goals</span>
              </v-stepper-step>
            </v-stepper-header>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            class="ma-auto align-center"
            :class="!$vuetify.breakpoint.mobile ? 'col-9' : 'col-11 mt-n4'"
          >
            <v-stepper-items
              class="border-radius-xl overflow-hidden shadow-sm mt-0"
              :class="$vuetify.breakpoint.mobile ? 'ma-0 mb-5' : 'ma-6'"
            >
              <v-stepper-content
                step="1"
                class="bg-white"
                :class="!$vuetify.breakpoint.mobile ? 'pa-15' : ''"
              >
                <v-card>
                  <v-row class="text-left">
                    <v-col class="px-4 col-12">
                      <h5
                        class="font-weight-normal mb-7 mt-1"
                        :class="!$vuetify.breakpoint.mobile ? 'text-h4 ' : 'text-h5'"
                      >
                        New Keyword Research Project
                      </h5>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="col-12 col-sm-6">
                      <label class="text-sm font-weight-bolder ms-3">
                        Project name:
                      </label>
                      <v-text-field
                        v-model="projectName"
                        hide-details
                        outlined
                        color="rgba(0,0,0,.6)"
                        light
                        placeholder="e.g., MyProject"
                        class="border border-radius-md"
                      />
                    </v-col>
                    <v-col class="col-12 col-sm-6">
                      <label class="text-sm font-weight-bolder ms-3">
                        Subject domain name:
                      </label>
                      <v-text-field
                        v-model="domainName"
                        hide-details
                        outlined
                        color="rgba(0,0,0,.6)"
                        light
                        placeholder="e.g., https://www.domain.com"
                        class="border border-radius-md"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="col-12 col-sm-6">
                      <label class="text-sm font-weight-bolder ms-3">
                        Type:
                      </label>
                      <v-radio-group
                        v-model="projectType"
                        class="ms-3 mt-1"
                        row
                      >
                        <v-radio
                          label="Website"
                          value="website"
                        />
                        <v-radio
                          label="Market Research"
                          value="market-research"
                        />
                      </v-radio-group>
                    </v-col>
                    <v-col class="col-12 col-sm-6">
                      <label class="text-sm font-weight-bolder ms-3">
                        Geographic area of audience:
                      </label>
                      <v-text-field
                        v-model="geoAudience"
                        hide-details
                        outlined
                        color="rgba(0,0,0,.6)"
                        light
                        placeholder=""
                        class="border border-radius-md"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row
                    :class="$vuetify.breakpoint.mobile ? 'mt-n5' : 'mt-n3'"
                  >
                    <v-col class="col-12 col-sm-6" />
                    <v-col class="col-12 col-sm-6">
                      <label class="text-sm font-weight-bolder ms-3">
                        Industry or market selector:
                      </label>
                      <v-select
                        :items="industriesMarkets"
                        class="input-style border-radius-md"
                        outlined
                        single-line
                        height="38"
                      />
                    </v-col>
                  </v-row>

                  <div class="text-end">
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-default
                        py-5
                        px-6
                        mt-2
                        mb-2
                        me-2
                      "
                      color="primary"
                      @click="stepperModel1 = 2"
                    >
                      Next
                    </v-btn>
                  </div>
                </v-card>
              </v-stepper-content>

              <v-stepper-content
                step="2"
                class="bg-white"
                :class="!$vuetify.breakpoint.mobile ? 'pa-15' : ''"
              >
                <v-card>
                  <div>
                    <v-row class="text-left">
                      <v-col class="px-4 col-12">
                        <v-icon
                          large
                          color="black"
                          class="float-left my-0 mr-2"
                        >
                          mdi-information-outline
                        </v-icon>
                        <h5
                          class="font-weight-normal mb-7 mt-1"
                          :class="!$vuetify.breakpoint.mobile ? 'text-h4 ' : 'text-h5'"
                        >
                          What's the purpose of the Website or Application?
                        </h5>
                        <p class="text-body text-sm">
                          Be as detailed as you can.  If you are struggling for an expansive description, type a brief one and click 'Expand with AI'
                        </p>
                        <v-textarea
                          solo
                          placeholder="Purpose (if you're doing market research, you can leave this blank)"
                          value=""
                        />
                        <v-btn
                          :ripple="false"
                          :elevation="0"
                          class="
                            font-weight-bold
                            text-xs text-dark
                            btn-light
                            py-5
                            px-6
                            mt-6
                            mb-2
                            ms-2
                          "
                        >
                          Expand with AI
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="d-flex mt-10">
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs text-dark
                        btn-light
                        bg-gradient-light
                        py-5
                        px-6
                        mt-6
                        mb-2
                        ms-2
                      "
                      @click="stepperModel1 = 1"
                    >
                      Prev
                    </v-btn>
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-default
                        py-5
                        px-6
                        mt-6
                        mb-2
                        me-2
                        ms-auto
                      "
                      color="primary"
                      @click="stepperModel1 = 3"
                    >
                      Next
                    </v-btn>
                  </div>
                </v-card>
              </v-stepper-content>

              <v-stepper-content
                step="3"
                class="bg-white"
                :class="!$vuetify.breakpoint.mobile ? 'pa-15' : ''"
              >
                <v-card>
                  <div>
                    <v-row class="text-left">
                      <v-col class="px-4 col-12">
                        <v-icon
                          large
                          color="black"
                          class="float-left my-0 mr-2"
                        >
                          mdi-information-outline
                        </v-icon>
                        <h5
                          class="font-weight-normal mb-7 mt-1"
                          :class="!$vuetify.breakpoint.mobile ? 'text-h4 ' : 'text-h5'"
                        >
                          What are your brand names?
                        </h5>
                        <p class="text-body text-sm">
                          Be as comprehensive as you can if the name is used as a trademark, even for a specific product.
                        </p>
                        <v-textarea
                          solo
                          placeholder=""
                          value=""
                        />
                      </v-col>
                    </v-row>
                  </div>
                  <div class="d-flex mt-10">
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs text-dark
                        btn-light
                        bg-gradient-light
                        py-5
                        px-6
                        mt-6
                        mb-2
                        ms-2
                      "
                      @click="stepperModel1 = 2"
                    >
                      Prev
                    </v-btn>
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-default
                        py-5
                        px-6
                        mt-6
                        mb-2
                        me-2
                        ms-auto
                      "
                      color="primary"
                      @click="stepperModel1 = 4"
                    >
                      Next
                    </v-btn>
                  </div>
                </v-card>
              </v-stepper-content>

              <v-stepper-content
                step="4"
                class="bg-white"
                :class="!$vuetify.breakpoint.mobile ? 'pa-15' : ''"
              >
                <v-card>
                  <div>
                    <v-row class="text-left">
                      <v-col class="px-4 col-12">
                        <v-icon
                          large
                          color="black"
                          class="float-left my-0 mr-2"
                        >
                          mdi-information-outline
                        </v-icon>
                        <h5
                          class="font-weight-normal mb-7 mt-1"
                          :class="!$vuetify.breakpoint.mobile ? 'text-h4 ' : 'text-h5'"
                        >
                          What are the brand names you compete against?
                        </h5>

                        <p class="text-body text-sm">
                          Be as comprehensive as you can if the name is used as a trademark, even for a specific product.
                        </p>
                        <v-textarea
                          solo
                          placeholder="Be as comprehensive as you can be, but you can always edit this selection later, so don't sweat it too much."
                          value=""
                        />
                        <v-btn
                          :ripple="false"
                          :elevation="0"
                          class="
                            font-weight-bold
                            text-xs text-dark
                            btn-light
                            py-5
                            px-6
                            mt-6
                            mb-2
                            ms-2
                          "
                        >
                          Expand with AI
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="d-flex mt-10">
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs text-dark
                        btn-light
                        bg-gradient-light
                        py-5
                        px-6
                        mt-6
                        mb-2
                        ms-2
                      "
                      @click="stepperModel1 = 3"
                    >
                      Prev
                    </v-btn>
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-default
                        py-5
                        px-6
                        mt-6
                        mb-2
                        me-2
                        ms-auto
                      "
                      color="primary"
                      @click="stepperModel1 = 5"
                    >
                      Next
                    </v-btn>
                  </div>
                </v-card>
              </v-stepper-content>

              <v-stepper-content
                step="5"
                class="bg-white"
                :class="!$vuetify.breakpoint.mobile ? 'pa-15' : ''"
              >
                <v-card>
                  <div>
                    <v-row class="text-left">
                      <v-col class="px-4 col-12">
                        <v-icon
                          large
                          color="black"
                          class="float-left my-0 mr-2"
                        >
                          mdi-information-outline
                        </v-icon>
                        <h5
                          class="font-weight-normal mb-7 mt-1"
                          :class="!$vuetify.breakpoint.mobile ? 'text-h4 ' : 'text-h5'"
                        >
                          What's the Conversion Goal?
                        </h5>
                        <p class="text-body text-sm">
                          What action do you want your ideal customer to take?
                        </p>
                        <v-textarea
                          solo
                          placeholder="What I want customers to do.  Here are some examples...
˖  Request information with a form
˖  Make a purchase
˖  Call our office

"
                          value=""
                        />
                        <v-btn
                          :ripple="false"
                          :elevation="0"
                          class="
                            font-weight-bold
                            text-xs text-dark
                            btn-light
                            py-5
                            px-6
                            mt-6
                            mb-2
                            ms-2
                          "
                        >
                          Give Me Ideas *
                        </v-btn>
                        <p class="text-body text-xs ml-3 font-italic">
                          * Ideas are generated by AI
                        </p>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="d-flex mt-10">
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs text-dark
                        btn-light
                        bg-gradient-light
                        py-5
                        px-6
                        mt-6
                        mb-2
                        ms-2
                      "
                      @click="stepperModel1 = 4"
                    >
                      Prev
                    </v-btn>
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-default
                        py-5
                        px-6
                        mt-6
                        mb-2
                        me-2
                        ms-auto
                      "
                      color="primary"
                    >
                      Finish
                    </v-btn>
                  </div>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-col>
        </v-row>
      </v-stepper>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Wizard",
  data() {
    return {
      stepperModel1: 1,
      projectName: '',
      domainName: '',
      projectType: null,
      geoAudience: '',
      industriesMarkets: [
        'car repair', 'cycling', 'auto retailer', 'legal services'
      ],
    };
  },
};
</script>
