var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "card-shadow border-radius-xl mt-6",
      attrs: { id: "sessions" },
    },
    [
      _c("div", { staticClass: "px-6 py-6" }, [
        _c("h5", { staticClass: "text-h5 font-weight-bold text-typo" }, [
          _vm._v("Sessions"),
        ]),
        _c("p", { staticClass: "text-sm text-body" }, [
          _vm._v(
            " This is a list of devices that have logged into your account. Remove those that you do not recognize. "
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "px-6 pb-6 pt-0 overflow-scroll" },
        _vm._l(_vm.sessions, function (item, i) {
          return _c("div", { key: item.title }, [
            _c(
              "div",
              { staticClass: "d-flex align-center ms-0" },
              [
                _c(
                  "div",
                  { staticClass: "text-center w-5" },
                  [
                    _c("v-icon", [
                      _vm._v(
                        _vm._s(item.icon) + " text-lg opacity-6 text-body"
                      ),
                    ]),
                  ],
                  1
                ),
                _c("div", { staticClass: "my-auto ms-4" }, [
                  _c("div", { staticClass: "h-100" }, [
                    _c(
                      "p",
                      {
                        staticClass: "text-sm mb-1 text-body",
                        attrs: { "v-if": item.title },
                      },
                      [_vm._v(" " + _vm._s(item.title) + " ")]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "mb-0 text-xs text-body",
                        attrs: { "v-if": item.description },
                      },
                      [_vm._v(" " + _vm._s(item.description) + " ")]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "my-auto text-body",
                        attrs: { "v-if": item.text },
                      },
                      [_vm._v(" " + _vm._s(item.text) + " ")]
                    ),
                  ]),
                ]),
                item.badge
                  ? _c(
                      "v-btn",
                      {
                        staticClass:
                          "border-radius-sm font-weight-bolder px-3 py-2 badge-font-size ms-auto text-success text-xxs me-4",
                        attrs: {
                          elevation: "0",
                          small: "",
                          ripple: false,
                          height: "21",
                          color: "#cdf59b",
                        },
                      },
                      [_vm._v(_vm._s(item.badge))]
                    )
                  : _vm._e(),
                _c(
                  "p",
                  {
                    staticClass: "text-secondary text-sm my-auto me-4",
                    class: item.badge ? "" : "ms-auto",
                  },
                  [_vm._v(" " + _vm._s(item.city) + " ")]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass:
                      "pe-4 ps-0 mb-0 text-primary text-capitalize icon-move-right align-center",
                    attrs: { ripple: false, icon: "", text: "", width: "auto" },
                  },
                  [
                    _vm._v(" See more "),
                    _c(
                      "v-icon",
                      {
                        staticClass: "ms-0 mt-1",
                        attrs: { right: "", dark: "", size: "12" },
                      },
                      [_vm._v(" fa fa-arrow-right ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            i != _vm.sessions.length - 1
              ? _c("hr", { staticClass: "horizontal dark my-4" })
              : _vm._e(),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }