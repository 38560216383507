<template>
  <v-card class="mt-sm-8 mt-md-5 mt-7 bg-transparent">
    <div class="card-padding pb-0">
      <h3 class="text-h3 font-weight-bolder text-primary text-gradient mb-2">
        Join us today
      </h3>
      <p class="mb-0 text-body">Verify your registration for SEO Team Flow</p>
    </div>
    <div class="card-padding pb-4">

      <label class="text-xs text-typo font-weight-bolder ms-1">Email</label>
      <v-text-field
        v-model="username"
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        placeholder="Email"
        class="
          input-style
          font-size-input
          border border-radius-md
          placeholder-lighter
          text-color-light
          mb-4
          mt-2
        "
      >
      </v-text-field>

      <label class="text-xs text-typo font-weight-bolder ms-1">Verification Code</label>
      <v-text-field
        v-model="code"
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        placeholder="Verification Code"
        class="
          input-style
          font-size-input
          border border-radius-md
          placeholder-lighter
          text-color-light
          mb-4
          mt-2
        "
      >
      </v-text-field>

      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        class="
          font-weight-bold
          text-uppercase
          btn-primary
          bg-gradient-primary
          py-2
          px-6
          me-2
          mt-7
          mb-2
          w-100
        "
        color="#5e72e4"
        small
        @click="verifyCode()"
        >Verify Registration</v-btn
      >
      <div class="text-center">
        <p class="text-sm text-body mt-3 mb-0">
          Already have an account?
          <a
            href="javascript:;"
            class="
              text-primary text-gradient text-decoration-none
              font-weight-bold
            "
            >Sign in</a
          >
        </p>
      </div>
    </div>
  </v-card>
</template>
<script>
import { Auth } from 'aws-amplify'
export default {
  name: "sign-up-cover",
  data() {
    return {
      username: '',
      code: '',
    }
  },
  methods: {

    async verifyCode () {
      const username = this.username
      const confirmationCode = this.code
      console.log('code', confirmationCode)
      try {
        Auth.confirmSignUp( username, confirmationCode );
        alert('Verification successfully confirmed. Please login')
        console.log('[isSignUpComplete, nextStep]', [isSignUpComplete, nextStep])
      } catch (error) {
        alert(error.message)
      }
    },

    signin () {
      this.$router.push({ path: '/pages/pages/account/settings' })
    }
  }
}
</script>
