var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "py-3", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-stepper",
            {
              staticClass: "bg-transparent w-100",
              attrs: { "alt-labels": "", elevation: "0" },
              model: {
                value: _vm.stepperModel1,
                callback: function ($$v) {
                  _vm.stepperModel1 = $$v
                },
                expression: "stepperModel1",
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "col-12 mx-auto mt-1" },
                    [
                      _c(
                        "v-stepper-header",
                        { staticClass: "shadow-0" },
                        [
                          _c(
                            "v-stepper-step",
                            { attrs: { step: "1", color: "#0A3542" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-secondary font-weight-normal",
                                },
                                [_vm._v("Project")]
                              ),
                            ]
                          ),
                          _c("v-divider"),
                          _c(
                            "v-stepper-step",
                            { attrs: { step: "2", color: "#0A3542" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-secondary font-weight-normal",
                                },
                                [_vm._v("Purpose")]
                              ),
                            ]
                          ),
                          _c("v-divider"),
                          _c(
                            "v-stepper-step",
                            { attrs: { step: "3", color: "#0A3542" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-secondary font-weight-normal",
                                },
                                [_vm._v("Brands")]
                              ),
                            ]
                          ),
                          _c("v-divider"),
                          _c(
                            "v-stepper-step",
                            { attrs: { step: "4", color: "#0A3542" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-secondary font-weight-normal",
                                },
                                [_vm._v("Competitors")]
                              ),
                            ]
                          ),
                          _c("v-divider"),
                          _c(
                            "v-stepper-step",
                            { attrs: { step: "5", color: "#0A3542" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-secondary font-weight-normal",
                                },
                                [_vm._v("Goals")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "ma-auto align-center",
                      class: !_vm.$vuetify.breakpoint.mobile
                        ? "col-9"
                        : "col-11 mt-n4",
                    },
                    [
                      _c(
                        "v-stepper-items",
                        {
                          staticClass:
                            "border-radius-xl overflow-hidden shadow-sm mt-0",
                          class: _vm.$vuetify.breakpoint.mobile
                            ? "ma-0 mb-5"
                            : "ma-6",
                        },
                        [
                          _c(
                            "v-stepper-content",
                            {
                              staticClass: "bg-white",
                              class: !_vm.$vuetify.breakpoint.mobile
                                ? "pa-15"
                                : "",
                              attrs: { step: "1" },
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "text-left" },
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "px-4 col-12" },
                                        [
                                          _c(
                                            "h5",
                                            {
                                              staticClass:
                                                "font-weight-normal mb-7 mt-1",
                                              class: !_vm.$vuetify.breakpoint
                                                .mobile
                                                ? "text-h4 "
                                                : "text-h5",
                                            },
                                            [
                                              _vm._v(
                                                " New Keyword Research Project "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "col-12 col-sm-6" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "text-sm font-weight-bolder ms-3",
                                            },
                                            [_vm._v(" Project name: ")]
                                          ),
                                          _c("v-text-field", {
                                            staticClass:
                                              "border border-radius-md",
                                            attrs: {
                                              "hide-details": "",
                                              outlined: "",
                                              color: "rgba(0,0,0,.6)",
                                              light: "",
                                              placeholder: "e.g., MyProject",
                                            },
                                            model: {
                                              value: _vm.projectName,
                                              callback: function ($$v) {
                                                _vm.projectName = $$v
                                              },
                                              expression: "projectName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "col-12 col-sm-6" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "text-sm font-weight-bolder ms-3",
                                            },
                                            [_vm._v(" Subject domain name: ")]
                                          ),
                                          _c("v-text-field", {
                                            staticClass:
                                              "border border-radius-md",
                                            attrs: {
                                              "hide-details": "",
                                              outlined: "",
                                              color: "rgba(0,0,0,.6)",
                                              light: "",
                                              placeholder:
                                                "e.g., https://www.domain.com",
                                            },
                                            model: {
                                              value: _vm.domainName,
                                              callback: function ($$v) {
                                                _vm.domainName = $$v
                                              },
                                              expression: "domainName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { staticClass: "col-12 col-sm-6" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "text-sm font-weight-bolder ms-3",
                                            },
                                            [_vm._v(" Type: ")]
                                          ),
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "ms-3 mt-1",
                                              attrs: { row: "" },
                                              model: {
                                                value: _vm.projectType,
                                                callback: function ($$v) {
                                                  _vm.projectType = $$v
                                                },
                                                expression: "projectType",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Website",
                                                  value: "website",
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Market Research",
                                                  value: "market-research",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { staticClass: "col-12 col-sm-6" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "text-sm font-weight-bolder ms-3",
                                            },
                                            [
                                              _vm._v(
                                                " Geographic area of audience: "
                                              ),
                                            ]
                                          ),
                                          _c("v-text-field", {
                                            staticClass:
                                              "border border-radius-md",
                                            attrs: {
                                              "hide-details": "",
                                              outlined: "",
                                              color: "rgba(0,0,0,.6)",
                                              light: "",
                                              placeholder: "",
                                            },
                                            model: {
                                              value: _vm.geoAudience,
                                              callback: function ($$v) {
                                                _vm.geoAudience = $$v
                                              },
                                              expression: "geoAudience",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      class: _vm.$vuetify.breakpoint.mobile
                                        ? "mt-n5"
                                        : "mt-n3",
                                    },
                                    [
                                      _c("v-col", {
                                        staticClass: "col-12 col-sm-6",
                                      }),
                                      _c(
                                        "v-col",
                                        { staticClass: "col-12 col-sm-6" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "text-sm font-weight-bolder ms-3",
                                            },
                                            [
                                              _vm._v(
                                                " Industry or market selector: "
                                              ),
                                            ]
                                          ),
                                          _c("v-select", {
                                            staticClass:
                                              "input-style border-radius-md",
                                            attrs: {
                                              items: _vm.industriesMarkets,
                                              outlined: "",
                                              "single-line": "",
                                              height: "38",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "text-end" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "font-weight-bold text-xs btn-default bg-gradient-default py-5 px-6 mt-2 mb-2 me-2",
                                          attrs: {
                                            ripple: false,
                                            elevation: 0,
                                            color: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.stepperModel1 = 2
                                            },
                                          },
                                        },
                                        [_vm._v(" Next ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            {
                              staticClass: "bg-white",
                              class: !_vm.$vuetify.breakpoint.mobile
                                ? "pa-15"
                                : "",
                              attrs: { step: "2" },
                            },
                            [
                              _c("v-card", [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "text-left" },
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "px-4 col-12" },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "float-left my-0 mr-2",
                                                attrs: {
                                                  large: "",
                                                  color: "black",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " mdi-information-outline "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "font-weight-normal mb-7 mt-1",
                                                class: !_vm.$vuetify.breakpoint
                                                  .mobile
                                                  ? "text-h4 "
                                                  : "text-h5",
                                              },
                                              [
                                                _vm._v(
                                                  " What's the purpose of the Website or Application? "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-body text-sm",
                                              },
                                              [
                                                _vm._v(
                                                  " Be as detailed as you can. If you are struggling for an expansive description, type a brief one and click 'Expand with AI' "
                                                ),
                                              ]
                                            ),
                                            _c("v-textarea", {
                                              attrs: {
                                                solo: "",
                                                placeholder:
                                                  "Purpose (if you're doing market research, you can leave this blank)",
                                                value: "",
                                              },
                                            }),
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "font-weight-bold text-xs text-dark btn-light py-5 px-6 mt-6 mb-2 ms-2",
                                                attrs: {
                                                  ripple: false,
                                                  elevation: 0,
                                                },
                                              },
                                              [_vm._v(" Expand with AI ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex mt-10" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "font-weight-bold text-xs text-dark btn-light bg-gradient-light py-5 px-6 mt-6 mb-2 ms-2",
                                        attrs: { ripple: false, elevation: 0 },
                                        on: {
                                          click: function ($event) {
                                            _vm.stepperModel1 = 1
                                          },
                                        },
                                      },
                                      [_vm._v(" Prev ")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "font-weight-bold text-xs btn-default bg-gradient-default py-5 px-6 mt-6 mb-2 me-2 ms-auto",
                                        attrs: {
                                          ripple: false,
                                          elevation: 0,
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.stepperModel1 = 3
                                          },
                                        },
                                      },
                                      [_vm._v(" Next ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            {
                              staticClass: "bg-white",
                              class: !_vm.$vuetify.breakpoint.mobile
                                ? "pa-15"
                                : "",
                              attrs: { step: "3" },
                            },
                            [
                              _c("v-card", [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "text-left" },
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "px-4 col-12" },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "float-left my-0 mr-2",
                                                attrs: {
                                                  large: "",
                                                  color: "black",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " mdi-information-outline "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "font-weight-normal mb-7 mt-1",
                                                class: !_vm.$vuetify.breakpoint
                                                  .mobile
                                                  ? "text-h4 "
                                                  : "text-h5",
                                              },
                                              [
                                                _vm._v(
                                                  " What are your brand names? "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-body text-sm",
                                              },
                                              [
                                                _vm._v(
                                                  " Be as comprehensive as you can if the name is used as a trademark, even for a specific product. "
                                                ),
                                              ]
                                            ),
                                            _c("v-textarea", {
                                              attrs: {
                                                solo: "",
                                                placeholder: "",
                                                value: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex mt-10" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "font-weight-bold text-xs text-dark btn-light bg-gradient-light py-5 px-6 mt-6 mb-2 ms-2",
                                        attrs: { ripple: false, elevation: 0 },
                                        on: {
                                          click: function ($event) {
                                            _vm.stepperModel1 = 2
                                          },
                                        },
                                      },
                                      [_vm._v(" Prev ")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "font-weight-bold text-xs btn-default bg-gradient-default py-5 px-6 mt-6 mb-2 me-2 ms-auto",
                                        attrs: {
                                          ripple: false,
                                          elevation: 0,
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.stepperModel1 = 4
                                          },
                                        },
                                      },
                                      [_vm._v(" Next ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            {
                              staticClass: "bg-white",
                              class: !_vm.$vuetify.breakpoint.mobile
                                ? "pa-15"
                                : "",
                              attrs: { step: "4" },
                            },
                            [
                              _c("v-card", [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "text-left" },
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "px-4 col-12" },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "float-left my-0 mr-2",
                                                attrs: {
                                                  large: "",
                                                  color: "black",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " mdi-information-outline "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "font-weight-normal mb-7 mt-1",
                                                class: !_vm.$vuetify.breakpoint
                                                  .mobile
                                                  ? "text-h4 "
                                                  : "text-h5",
                                              },
                                              [
                                                _vm._v(
                                                  " What are the brand names you compete against? "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-body text-sm",
                                              },
                                              [
                                                _vm._v(
                                                  " Be as comprehensive as you can if the name is used as a trademark, even for a specific product. "
                                                ),
                                              ]
                                            ),
                                            _c("v-textarea", {
                                              attrs: {
                                                solo: "",
                                                placeholder:
                                                  "Be as comprehensive as you can be, but you can always edit this selection later, so don't sweat it too much.",
                                                value: "",
                                              },
                                            }),
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "font-weight-bold text-xs text-dark btn-light py-5 px-6 mt-6 mb-2 ms-2",
                                                attrs: {
                                                  ripple: false,
                                                  elevation: 0,
                                                },
                                              },
                                              [_vm._v(" Expand with AI ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex mt-10" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "font-weight-bold text-xs text-dark btn-light bg-gradient-light py-5 px-6 mt-6 mb-2 ms-2",
                                        attrs: { ripple: false, elevation: 0 },
                                        on: {
                                          click: function ($event) {
                                            _vm.stepperModel1 = 3
                                          },
                                        },
                                      },
                                      [_vm._v(" Prev ")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "font-weight-bold text-xs btn-default bg-gradient-default py-5 px-6 mt-6 mb-2 me-2 ms-auto",
                                        attrs: {
                                          ripple: false,
                                          elevation: 0,
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.stepperModel1 = 5
                                          },
                                        },
                                      },
                                      [_vm._v(" Next ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            {
                              staticClass: "bg-white",
                              class: !_vm.$vuetify.breakpoint.mobile
                                ? "pa-15"
                                : "",
                              attrs: { step: "5" },
                            },
                            [
                              _c("v-card", [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "text-left" },
                                      [
                                        _c(
                                          "v-col",
                                          { staticClass: "px-4 col-12" },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "float-left my-0 mr-2",
                                                attrs: {
                                                  large: "",
                                                  color: "black",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " mdi-information-outline "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "h5",
                                              {
                                                staticClass:
                                                  "font-weight-normal mb-7 mt-1",
                                                class: !_vm.$vuetify.breakpoint
                                                  .mobile
                                                  ? "text-h4 "
                                                  : "text-h5",
                                              },
                                              [
                                                _vm._v(
                                                  " What's the Conversion Goal? "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-body text-sm",
                                              },
                                              [
                                                _vm._v(
                                                  " What action do you want your ideal customer to take? "
                                                ),
                                              ]
                                            ),
                                            _c("v-textarea", {
                                              attrs: {
                                                solo: "",
                                                placeholder:
                                                  "What I want customers to do.  Here are some examples...\n˖  Request information with a form\n˖  Make a purchase\n˖  Call our office\n\n",
                                                value: "",
                                              },
                                            }),
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "font-weight-bold text-xs text-dark btn-light py-5 px-6 mt-6 mb-2 ms-2",
                                                attrs: {
                                                  ripple: false,
                                                  elevation: 0,
                                                },
                                              },
                                              [_vm._v(" Give Me Ideas * ")]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "text-body text-xs ml-3 font-italic",
                                              },
                                              [
                                                _vm._v(
                                                  " * Ideas are generated by AI "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex mt-10" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "font-weight-bold text-xs text-dark btn-light bg-gradient-light py-5 px-6 mt-6 mb-2 ms-2",
                                        attrs: { ripple: false, elevation: 0 },
                                        on: {
                                          click: function ($event) {
                                            _vm.stepperModel1 = 4
                                          },
                                        },
                                      },
                                      [_vm._v(" Prev ")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "font-weight-bold text-xs btn-default bg-gradient-default py-5 px-6 mt-6 mb-2 me-2 ms-auto",
                                        attrs: {
                                          ripple: false,
                                          elevation: 0,
                                          color: "primary",
                                        },
                                      },
                                      [_vm._v(" Finish ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }