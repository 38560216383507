var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "py-6", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "me-auto text-start", attrs: { md: "8" } },
            [
              _c(
                "h5",
                { staticClass: "text-h5 text-typo font-weight-bold mb-2" },
                [_vm._v(" Some of Our Awesome Projects ")]
              ),
              _c("p", { staticClass: "text-body" }, [
                _vm._v(
                  " This is the paragraph where you can write more details about your projects. Keep you user engaged by providing meaningful information. "
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _vm._l(_vm.cards, function (card) {
            return _c(
              "v-col",
              { key: card.title, attrs: { lg: "4", md: "6", cols: "12" } },
              [
                _c("v-card", { staticClass: "card-shadow border-radius-xl" }, [
                  _c(
                    "div",
                    { staticClass: "px-4 py-4" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "v-avatar",
                            {
                              staticClass:
                                "border-radius-md bg-gradient-default pa-2",
                              attrs: { size: "74" },
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  src: card.image,
                                  width: "50",
                                  height: "50",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ms-4 my-auto" }, [
                            _c(
                              "h6",
                              {
                                staticClass:
                                  "text-h6 text-typo font-weight-bold mb-2",
                              },
                              [_vm._v(" " + _vm._s(card.title) + " ")]
                            ),
                            _c(
                              "span",
                              { staticClass: "avatar-group d-flex" },
                              _vm._l(card.avatars, function (avatar) {
                                return _c(
                                  "v-avatar",
                                  {
                                    key: avatar.image,
                                    staticClass: "border border-white",
                                    attrs: { size: "24" },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: avatar.image,
                                        alt: "Avatar",
                                      },
                                    }),
                                  ]
                                )
                              }),
                              1
                            ),
                          ]),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                transition: "slide-y-transition",
                                "offset-y": "",
                                "offset-x": "",
                                "min-width": "150",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "text-secondary ms-auto mt-3",
                                                attrs: {
                                                  icon: "",
                                                  ripple: false,
                                                  small: "",
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { size: "16" } },
                                              [_vm._v("fas fa-ellipsis-v")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                { staticClass: "py-0" },
                                [
                                  _c(
                                    "v-list-item",
                                    { staticClass: "list-item-hover-active" },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        { staticClass: "pa-0" },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "ls-0 text-body font-weight-600 mb-0",
                                            },
                                            [_vm._v(" Action ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    { staticClass: "list-item-hover-active" },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        { staticClass: "pa-0" },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "ls-0 text-body font-weight-600 mb-0",
                                            },
                                            [_vm._v(" Another Action ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    { staticClass: "list-item-hover-active" },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        { staticClass: "pa-0" },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            {
                                              staticClass:
                                                "ls-0 text-body font-weight-600 mb-0",
                                            },
                                            [_vm._v(" Something else here ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("hr", { staticClass: "horizontal dark" }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("p", { staticClass: "text-sm mt-4 text-body" }, [
                        _vm._v(" " + _vm._s(card.description) + " "),
                      ]),
                      _c("hr", { staticClass: "horizontal dark mb-3" }),
                      _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "6" } }, [
                            _c(
                              "h6",
                              {
                                staticClass:
                                  "text-sm font-weight-bold text-typo mb-0",
                              },
                              [_vm._v(" " + _vm._s(card.participants) + " ")]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-secondary text-sm font-weight-bold mb-0",
                              },
                              [_vm._v(" Participants ")]
                            ),
                          ]),
                          _c(
                            "v-col",
                            { staticClass: "text-end", attrs: { cols: "6" } },
                            [
                              _c(
                                "h6",
                                { staticClass: "text-sm text-typo mb-0" },
                                [_vm._v(_vm._s(card.date))]
                              ),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-secondary text-sm font-weight-bold mb-0",
                                },
                                [_vm._v(" Due date ")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            )
          }),
          _c(
            "v-col",
            { attrs: { lg: "4", md: "6", cols: "12" } },
            [
              _c(
                "v-card",
                {
                  staticClass:
                    "border-radius-xl overflow-hidden card-shadow h-100",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "px-4 py-4 d-flex flex-column justify-center text-center h-100",
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "text-decoration-none",
                          attrs: { href: "javascript:;" },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fa fa-plus text-secondary text-sm mb-4",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _c(
                            "h5",
                            {
                              staticClass:
                                "text-h5 text-secondary font-weight-bold",
                            },
                            [_vm._v(" New project ")]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }