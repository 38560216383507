var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "card-shadow border-radius-xl mt-6",
      attrs: { id: "delete" },
    },
    [
      _c("div", { staticClass: "px-6 py-6" }, [
        _c("h5", { staticClass: "text-h5 font-weight-bold text-typo" }, [
          _vm._v("Delete Account"),
        ]),
        _c("p", { staticClass: "text-sm text-body" }, [
          _vm._v(
            " Once you delete your account, there is no going back. Please be certain. "
          ),
        ]),
      ]),
      _c("div", { staticClass: "px-6 pb-6 pt-0" }, [
        _c(
          "div",
          { staticClass: "d-sm-flex align-center ms-2" },
          [
            _c("v-switch", {
              staticClass: "mt-0 pt-0 switch",
              attrs: {
                ripple: false,
                "hide-details": "",
                color: "#3a416ff2",
                inset: "",
              },
              model: {
                value: _vm.switche,
                callback: function ($$v) {
                  _vm.switche = $$v
                },
                expression: "switche",
              },
            }),
            _c("div", { staticClass: "ms-sm-3 mb-sm-0 mb-3" }, [
              _c(
                "span",
                { staticClass: "text-dark font-weight-bold d-block text-sm" },
                [_vm._v("Confirm")]
              ),
              _c("span", { staticClass: "text-xs text-body d-block" }, [
                _vm._v("I want to delete my account."),
              ]),
            ]),
            _c(
              "v-btn",
              {
                staticClass:
                  "font-weight-bolder btn-outline-secondary py-5 px-6 ms-auto",
                attrs: {
                  ripple: false,
                  elevation: "0",
                  color: "#fff",
                  small: "",
                },
              },
              [_vm._v(" Deactivate ")]
            ),
            _c(
              "v-btn",
              {
                staticClass:
                  "font-weight-bolder btn-danger bg-gradient-danger py-5 px-6 ms-2",
                attrs: {
                  ripple: false,
                  elevation: "0",
                  color: "#fff",
                  small: "",
                },
              },
              [_vm._v(" Delete Account ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }